import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import BaseButton from "../components/Base/BaseButton/BaseButton";
import BaseInput from "../components/Base/BaseInput/BaseInput";
import GridState from "../components/Core/GridState";
import MembersGrid from "../components/Core/MembersGrid";
import WelcomeCounter from "../components/Core/WelcomeCounter";
import skills from "../configs/skills.config";
import UIContext from "../contexts/ui.ctx";
import $firestore from "../services/app/firebase-service";
import "./Home.css";
import { onAuthStateChanged } from "firebase/auth";
import { $firebaseAuth } from "../services/app/firebase-service";
import { useLocation, useNavigate } from "react-router-dom";

type MemberType = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  mainSkill: string;
  skills: string[];
  subSkills: string[];
  status: string;
  startingYear: number;
  profilePictureUrl: string;
  linkedinId: string;
  rewards: string[];
  portfolioUrl: string;
  geoId: string;
  githubId: string;
  contestantExperience: number;
};

const Home: React.FC = () => {
  const [usersCount, setUsersCount] = useState(0);
  const [visibleUsersCount, setVisibleUsersCount] = useState(0);
  const [membersList, setMembersList] = useState([] as MemberType[]);
  const [documentSnapshots, setDocumentSnapshots] = useState(
    {} as QuerySnapshot<DocumentData>
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [pseudoSearch, setPseudoSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([] as MemberType[]);
  const [skillFilter, setSkillFilter] = useState("");
  const [skillFilteredResults, setSkillFilteredResults] = useState(
    [] as MemberType[]
  );
  const [skillFilteredDocumentSnapshots, setSkillFilteredDocumentSnapshots] =
    useState({} as QuerySnapshot<DocumentData>);
  const [authState, setAuthState] = useState("UNDEFINED");

  const uiCtx = useContext(UIContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(1);
  }, [uiCtx.returnToFirstPageTrigger]);

  useEffect(() => {
    onAuthStateChanged($firebaseAuth, (user) => {
      if (user && authState === "UNDEFINED") {
        setAuthState("AUTH");
      } else if (!user && authState === "UNDEFINED") {
        setAuthState("ANON");
      }
    });
  }, [authState]);

  //ccc.hugo.t@gmail.com

  useEffect(() => {
    // TODO issue when sign in
    if (authState === "AUTH" && !location.state) {
      navigate("/my-account");
      return;
    }
    if (authState === "UNDEFINED") {
      return;
    }
    getDocs(
      query(
        collection($firestore, "users"),
        where("hasMinimalProfile", "==", true),
        orderBy("contestantExperience", "desc"),
        orderBy("createdAt", "asc"),
        limit(20)
      )
    )
      .then((docs) => {
        const list = [] as MemberType[];
        docs.forEach((doc) =>
          list.push({
            id: doc.id,
            username: doc.data().username,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            mainSkill: doc.data().mainSkill,
            skills: doc.data().skillsIds || [],
            status: doc.data().status,
            startingYear: doc.data().startingYear,
            profilePictureUrl: doc.data().profilePictureUrl,
            linkedinId: doc.data().linkedinId,
            subSkills: doc.data().subSkillsIds || [],
            rewards: doc.data().rewardsIds || [],
            portfolioUrl: doc.data().portfolioUrl || "",
            geoId: doc.data().geoId || "",
            githubId: doc.data().githubId || "",
            contestantExperience: doc.data().contestantExperience || 0,
          })
        );
        setDocumentSnapshots(docs);
        setMembersList(list);
        setIsLoadingMore(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Une erreur inconnue est survenue");
        setIsLoadingMore(false);
      });
    getDoc(doc($firestore, "app_stats", "users")).then((usersStats) => {
      if (usersStats.exists()) {
        // setUsersCount(358);
        setUsersCount(usersStats.data().count);
        setVisibleUsersCount(usersStats.data().visibleCount);
      }
    });
  }, [authState, navigate, location.state]);

  useEffect(() => {
    setIsSearching(true);
    if (!pseudoSearch) {
      setIsSearching(false);
      return setSearchResults([]);
    }
    const timer = setTimeout(() => {
      getDocs(
        query(
          collection($firestore, "users"),
          where("lcUsername", "==", pseudoSearch.toLowerCase()),
          where("hasMinimalProfile", "==", true)
        )
      ).then((docs) => {
        const list = [] as MemberType[];
        docs.forEach((doc) =>
          list.push({
            id: doc.id,
            username: doc.data().username,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            mainSkill: doc.data().mainSkill,
            skills: doc.data().skillsIds || [],
            status: doc.data().status,
            startingYear: doc.data().startingYear,
            profilePictureUrl: doc.data().profilePictureUrl,
            linkedinId: doc.data().linkedinId,
            subSkills: doc.data().subSkillsIds || [],
            rewards: doc.data().rewardsIds || [],
            portfolioUrl: doc.data().portfolioUrl || "",
            geoId: doc.data().geoId || "",
            githubId: doc.data().githubId || "",
            contestantExperience: doc.data().contestantExperience || 0,
          })
        );

        setSearchResults(list);
        setIsSearching(false);
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [pseudoSearch]);

  const loadMoreSkillFilteredResults = () => {
    const lastSkillResultsVisible =
      skillFilteredDocumentSnapshots?.docs?.length &&
      skillFilteredDocumentSnapshots.docs[
        skillFilteredDocumentSnapshots.docs.length - 1
      ];
    getDocs(
      query(
        collection($firestore, "users"),
        where("skillsIds", "array-contains", skillFilter),
        where("hasMinimalProfile", "==", true),
        orderBy("contestantExperience", "desc"),
        orderBy("createdAt", "asc"),
        startAfter(lastSkillResultsVisible),
        limit(20)
      )
    ).then((docs) => {
      const list = [] as MemberType[];
      docs.forEach((doc) => {
        list.push({
          id: doc.id,
          username: doc.data().username,
          firstname: doc.data().firstname,
          lastname: doc.data().lastname,
          mainSkill: doc.data().mainSkill,
          skills: doc.data().skillsIds || [],
          status: doc.data().status,
          startingYear: doc.data().startingYear,
          profilePictureUrl: doc.data().profilePictureUrl,
          linkedinId: doc.data().linkedinId,
          subSkills: doc.data().subSkillsIds || [],
          rewards: doc.data().rewardsIds || [],
          portfolioUrl: doc.data().portfolioUrl || "",
          geoId: doc.data().geoId || "",
          githubId: doc.data().githubId || "",
          contestantExperience: doc.data().contestantExperience || 0,
        });
      });
      setSkillFilteredDocumentSnapshots(docs);
      setSkillFilteredResults([...skillFilteredResults, ...list]);
      setIsSearching(false);
    });
  };

  useEffect(() => {
    if (!skillFilter) {
      setSkillFilteredResults([]);
      setSkillFilteredDocumentSnapshots({} as QuerySnapshot<DocumentData>);
      return;
    }

    getDocs(
      query(
        collection($firestore, "users"),
        where("skillsIds", "array-contains", skillFilter),
        where("hasMinimalProfile", "==", true),
        orderBy("contestantExperience", "desc"),
        orderBy("createdAt", "asc"),
        limit(20)
      )
    ).then((docs) => {
      const list = [] as MemberType[];
      docs.forEach((doc) => {
        list.push({
          id: doc.id,
          username: doc.data().username,
          firstname: doc.data().firstname,
          lastname: doc.data().lastname,
          mainSkill: doc.data().mainSkill,
          skills: doc.data().skillsIds || [],
          status: doc.data().status,
          startingYear: doc.data().startingYear,
          profilePictureUrl: doc.data().profilePictureUrl,
          linkedinId: doc.data().linkedinId,
          subSkills: doc.data().subSkillsIds || [],
          rewards: doc.data().rewardsIds || [],
          portfolioUrl: doc.data().portfolioUrl || "",
          geoId: doc.data().geoId || "",
          githubId: doc.data().githubId || "",
          contestantExperience: doc.data().contestantExperience || 0,
        });
      });
      setSkillFilteredDocumentSnapshots(docs);
      setSkillFilteredResults(list);
      setIsSearching(false);
    });
  }, [skillFilter]);

  const loadMoreHandler = () => {
    // TODO check if enough data no need for load more
    setIsLoadingMore(true);
    if ([...membersList].slice((currentPage + 1) * 20).length) {
      setIsLoadingMore(false);
      return;
    }
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    getDocs(
      query(
        collection($firestore, "users"),
        where("hasMinimalProfile", "==", true),
        orderBy("contestantExperience", "desc"),
        orderBy("createdAt", "asc"),
        startAfter(lastVisible),
        limit(20)
      )
    )
      .then((docs) => {
        const list = [] as MemberType[];
        docs.forEach((doc) =>
          list.push({
            id: doc.id,
            username: doc.data().username,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            mainSkill: doc.data().mainSkill,
            skills: doc.data().skillsIds || [],
            status: doc.data().status,
            startingYear: doc.data().startingYear,
            profilePictureUrl: doc.data().profilePictureUrl,
            linkedinId: doc.data().linkedinId,
            subSkills: doc.data().subSkillsIds || [],
            rewards: doc.data().rewardsIds || [],
            portfolioUrl: doc.data().portfolioUrl || "",
            geoId: doc.data().geoId || "",
            githubId: doc.data().githubId || "",
            contestantExperience: doc.data().contestantExperience || 0,
          })
        );
        setDocumentSnapshots(docs);
        setMembersList([...membersList, ...list]);
        setIsLoadingMore(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingMore(false);
        uiCtx.setAlertPrompt({
          type: "Erreur",
          message: "Une erreur est survenue",
        });
      });
  };

  const isPaginationVisible = () => {
    if (isSearching) {
      return false;
    }
    if (pseudoSearch) {
      return false;
    }
    if (!skillFilteredResults.length && skillFilter) {
      return false;
    }
    return true;
  };

  return (
    <>
      {/* <Header /> */}
      {/* <NavBar /> */}
      <section className="top-section">
        <WelcomeCounter count={usersCount} />
        <div className="search-actions">
          <div className="skill-selector">
            <label>Compétence :</label>
            <select
              name="skill"
              id="skill-select"
              onChange={(event) => setSkillFilter(event.target.value)}
              value={skillFilter}
            >
              <option value="">-</option>
              {Object.keys(skills).map((skillKey: string) => (
                <option
                  key={"select-" + skills[skillKey].id}
                  value={skills[skillKey].id}
                >
                  {skills[skillKey].name}
                </option>
              ))}
            </select>
          </div>
          <div className="search-actions__pseudo-search">
            <BaseInput
              value={pseudoSearch}
              onValueChange={(val: string) => setPseudoSearch(val)}
              label="Rechercher un membre"
              placeholder="Pseudo..."
              noMargin
            />
          </div>
        </div>
      </section>
      {isSearching ? (
        <GridState state="SEARCHING" />
      ) : isLoadingMore ? (
        <GridState state="LOADING" />
      ) : pseudoSearch && !searchResults.length ? (
        <GridState state="NO_RESULTS" />
      ) : skillFilter && !skillFilteredResults.length ? (
        <GridState state="NO_RESULTS" />
      ) : (
        <MembersGrid
          membersList={
            searchResults.length
              ? searchResults
              : skillFilteredResults.length
              ? skillFilteredResults
              : [...membersList].slice((currentPage - 1) * 20, currentPage * 20)
          }
        />
      )}
      {isPaginationVisible() && (
        <div
          className="load-more"
          style={{
            justifyContent: skillFilteredResults.length ? "center" : undefined,
          }}
        >
          {!skillFilteredResults.length && (
            <>
              <div className="load-more__button">
                <BaseButton
                  name="Précedent"
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  extended
                  disabled={currentPage === 1}
                  isLoading={isLoadingMore}
                />
              </div>
              <span>
                {currentPage} / {Math.ceil(visibleUsersCount / 20)}
              </span>
            </>
          )}
          <div className={"load-more__button"}>
            <BaseButton
              name={skillFilteredResults.length ? "Charger plus" : "Suivant"}
              onClick={() => {
                const normalMode = () => {
                  setCurrentPage(currentPage + 1);
                  loadMoreHandler();
                };
                !skillFilteredResults.length
                  ? normalMode()
                  : loadMoreSkillFilteredResults();
              }}
              extended
              disabled={currentPage === Math.ceil(visibleUsersCount / 20)}
              isLoading={isLoadingMore}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
