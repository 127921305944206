import config from "../../configs/firebase.config";

import { getApps, initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = config();

if (getApps().length < 1) {
  initializeApp(firebaseConfig.sdkCredentials);
}

const $firestore = getFirestore();
const $firebaseAuth = getAuth();
const $firebaseFunctions = getFunctions();
const $firebaseStorage = getStorage();

if (firebaseConfig.envName === "EMU") {
  connectAuthEmulator(
    $firebaseAuth,
    `http://${firebaseConfig.emulators.auth.host}:${firebaseConfig.emulators.auth.port}`
  );
  connectFirestoreEmulator(
    $firestore,
    firebaseConfig.emulators.firestore.host,
    firebaseConfig.emulators.firestore.port
  );
  connectStorageEmulator(
    $firebaseStorage,
    firebaseConfig.emulators.storage.host,
    firebaseConfig.emulators.storage.port
  );
  connectFunctionsEmulator(
    $firebaseFunctions,
    firebaseConfig.emulators.functions.host,
    firebaseConfig.emulators.functions.port
  );
}

export { $firestore, $firebaseFunctions, $firebaseAuth, $firebaseStorage };
export default $firestore;
