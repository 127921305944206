import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faBookAtlas,
  faEye,
  faGraduationCap,
  faLaptopCode,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import skillsConfig from "../../configs/skills.config";
import UIContext from "../../contexts/ui.ctx";
import BaseIcon from "../Base/BaseIcon/BaseIcon";
import "./MemberCard.css";
import PPPlaceholder from "./PPPlaceholder";
import SkillIcon from "./SkillIcon";
import dcLogo from "../../assets/logo/colored-solo.svg";

interface MemberCardProps {
  username: string;
  startingYear: number;
  profilePictureUrl: string;
  firstname: string;
  lastname: string;
  mainSkill: string;
  skills: string[];
  subSkills: string[];
  status: "WORKER" | "STUDENT" | "OBSERVER";
  linkedinId: string;
  githubId: string;
  portfolioUrl: string;
  rewardsIds: string[];
  geoId: string;
  contestantExperience: number;
}

const MemberCard: React.FC<MemberCardProps> = (props) => {
  const uiCtx = useContext(UIContext);

  const navigate = useNavigate();

  const getStartingYear = () => {
    const now = new Date().getFullYear();
    return now - props.startingYear;
  };

  const memberClickHandler = () => {
    navigate("/u/" + props.username);
  };

  return (
    <div className="member-card" onClick={memberClickHandler}>
      <div className="member-card__main-infos">
        {props.profilePictureUrl ? (
          <img src={props.profilePictureUrl} alt="user-profile" />
        ) : (
          <PPPlaceholder />
        )}
        <div className="member-card__main-infos__identity">
          <span>{props.firstname || "-"}</span>
          <span>{props.lastname || "-"}</span>
          <span>{props.username ? `#${props.username}` : "-"}</span>
        </div>
      </div>
      <div className="member-card__sub-infos">
        <span>
          <BaseIcon
            icon={faLocationDot}
            size={"0.825rem"}
            color={"var(--primary-color)"}
          />{" "}
          {props.geoId !== "00" && props.geoId
            ? "France - " + props.geoId
            : "-"}
        </span>
        <span>
          {
            <BaseIcon
              icon={
                props.status === "WORKER"
                  ? faLaptopCode
                  : props.status === "OBSERVER"
                  ? faEye
                  : faGraduationCap
              }
              size={"0.825rem"}
              color={"var(--primary-color)"}
            />
          }
          {props.status === "WORKER"
            ? getStartingYear().toString() + " ans d'XP"
            : props.status === "OBSERVER"
            ? "Observe"
            : "Se forme"}
        </span>
        <span
          onMouseEnter={() => uiCtx.setMouseInfo("Points devclub")}
          onMouseLeave={() => uiCtx.setMouseInfo("")}
          onTouchStart={() => uiCtx.setMouseInfo("")}
          onTouchEnd={() => uiCtx.setMouseInfo("")}
        >
          <img className="dc-logo-scoring" src={dcLogo} alt="dc-logo-score" />
          {props.contestantExperience === 987654321
            ? "😎"
            : props.contestantExperience}
        </span>
      </div>
      <div className="member-card__more">
        <div className="member-card__more__skills">
          {props.skills.map((skillKey: string, index) => {
            if (index > 4) {
              return null;
            }
            return (
              <SkillIcon key={skillKey} skill={skillsConfig[skillKey]} mini />
            );
          })}
          {Array.from(Array(5 - props.skills.length)).map((k, i) => (
            <div
              key={"ph" + i}
              className="member-card__more__skill-placeholder"
            />
          ))}
          {props.subSkills.length > 0 && (
            <div className="member-card__more__skills__more">
              +{props.subSkills.length}
            </div>
          )}
        </div>

        <div className="member-card__more__socials">
          <div className="member-card__more__socials__display">
            <span
              style={{ opacity: props.portfolioUrl ? 1 : 0.3 }}
              onMouseEnter={() =>
                uiCtx.setMouseInfo(props.portfolioUrl ? "Portfolio" : "")
              }
              onMouseLeave={() => uiCtx.setMouseInfo("")}
              onTouchStart={() => uiCtx.setMouseInfo("")}
              onTouchEnd={() => uiCtx.setMouseInfo("")}
            >
              <BaseIcon icon={faBookAtlas} size="1.25rem" />
            </span>
            <span
              style={{ opacity: props.linkedinId ? 1 : 0.3 }}
              onMouseEnter={() =>
                uiCtx.setMouseInfo(props.linkedinId ? "LinkedIn" : "")
              }
              onMouseLeave={() => uiCtx.setMouseInfo("")}
              onTouchStart={() => uiCtx.setMouseInfo("")}
              onTouchEnd={() => uiCtx.setMouseInfo("")}
            >
              <BaseIcon icon={faLinkedin} size="1.25rem" />
            </span>
            <span
              style={{ opacity: props.githubId ? 1 : 0.3 }}
              onMouseEnter={() =>
                uiCtx.setMouseInfo(props.githubId ? "Github" : "")
              }
              onMouseLeave={() => uiCtx.setMouseInfo("")}
              onTouchStart={() => uiCtx.setMouseInfo("")}
              onTouchEnd={() => uiCtx.setMouseInfo("")}
            >
              <BaseIcon icon={faGithub} size="1.25rem" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
