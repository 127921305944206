import MemberCard from "./MemberCard";
import "./MembersGrid.css";

type MemberType = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  mainSkill: string;
  skills: string[];
  subSkills: string[];
  status: string;
  startingYear: number;
  profilePictureUrl: string;
  linkedinId: string;
  rewards: string[];
  portfolioUrl: string;
  geoId: string;
  githubId: string;
  contestantExperience: number;
};
interface MembersGridProps {
  membersList: MemberType[];
}
const MembersGrid: React.FC<MembersGridProps> = (props) => {
  return (
    <div className="members-grid">
      {props.membersList.map((member) => {
        return (
          <MemberCard
            username={member.username}
            key={member.id}
            firstname={member.firstname}
            lastname={member.lastname}
            linkedinId={member.linkedinId}
            mainSkill={member.mainSkill}
            skills={member.skills}
            profilePictureUrl={member.profilePictureUrl}
            startingYear={member.startingYear}
            status={member.status as "WORKER" | "STUDENT" | "OBSERVER"}
            subSkills={member.subSkills}
            rewardsIds={member.rewards}
            portfolioUrl={member.portfolioUrl}
            geoId={member.geoId}
            githubId={member.githubId}
            contestantExperience={member.contestantExperience}
          />
        );
      })}
    </div>
  );
};

export default MembersGrid;
