import buildEnvConfig from "../build.env.json";
const emulators = {
  auth: {
    host: "localhost",
    port: 9099,
  },
  functions: {
    host: "localhost",
    port: 5001,
  },
  firestore: {
    host: "localhost",
    port: 8080,
  },
  storage: {
    host: "localhost",
    port: 9199,
  },
};

const devConfig = {
  envName: "DEV",
  functionsEntryPoint: "https://us-central1-devclub-dev.cloudfunctions.net/",
  sdkCredentials: {
    apiKey: "AIzaSyB5Q-Jc-JOui5bZ9KzGlpdEtJgLpxcCut8",
    authDomain: "devclub-dev.firebaseapp.com",
    projectId: "devclub-dev",
    storageBucket: "devclub-dev.appspot.com",
    messagingSenderId: "208911537100",
    appId: "1:208911537100:web:e777920641c50b94828369",
    measurementId: "G-4L5FD344W6",
  },
  emulators: { ...emulators },
};
const prodConfig = {
  envName: "PROD",
  functionsEntryPoint: "https://us-central1-devclub-prod.cloudfunctions.net/",
  sdkCredentials: {
    apiKey: "AIzaSyCB1vT3HoiQZ42a9aS_mHXlpp03YUYRsGY",
    authDomain: "devclub-prod.firebaseapp.com",
    projectId: "devclub-prod",
    storageBucket: "devclub-prod.appspot.com",
    messagingSenderId: "821648851029",
    appId: "1:821648851029:web:d7de5cf0eff3331d4654dc",
    measurementId: "G-S65D7Q1QLV",
  },
  emulators: { ...emulators },
};
const emuConfig = {
  envName: "EMU",
  functionsEntryPoint: "http://localhost:5001/devclub-dev/us-central1/",
  sdkCredentials: devConfig.sdkCredentials,
  emulators: { ...emulators },
};

const config = () => {
  if (buildEnvConfig.BUILD_ENV === "PROD") {
    return prodConfig;
  } else if (buildEnvConfig.BUILD_ENV === "DEV") {
    return devConfig;
  } else {
    return emuConfig;
  }
};

export default config;
