import SignInForm from "../components/Forms/SignInForm";
import LoginLayout from "../components/Layout/LoginLayout";

const SignIn: React.FC = () => {
  return (
    <LoginLayout formTitle="Se connecter">
      <SignInForm />
    </LoginLayout>
  );
};
export default SignIn;
