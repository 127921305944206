import "./WelcomeCounter.css";

interface WelcomeCounterProps {
  count: number;
}

const WelcomeCounter: React.FC<WelcomeCounterProps> = ({ count }) => {
  return (
    <div className="welcome-counter">
      <div className="welcome-counter__sub-counter">
        <span>
          <span>{count || "-"}</span>
          <span>membres</span>
        </span>
      </div>
      {/* <div className="welcome-counter__contest-cta">
        <span>Info : </span>
        <span>
          Rejoins gratuitement le programme <span>DevClub Engage</span> et tente
          de gagner régulièrement des cadeaux{" "}
          <span className="welcome-counter__contest-cta__gifts">
            (Nintendo Switch, AirPods...)
          </span>{" "}
          ! <a href="engage">Plus d'infos ici 🎁 !</a>
        </span>
      </div> */}

      {/* {count || (
            <BaseLoadingCircle mini color="var(--primary-color)" />
          )} */}
      {/* <span>
        Bienvenue dans le Dev Club 🙌 ! Nous sommes déjà
        <span className="welcome-counter__count">
          {" "}
          {count || (
            <BaseLoadingCircle mini color="var(--primary-color)" />
          )}{" "}
          membres
        </span>{" "}
        🤙 !
      </span> */}
    </div>
  );
};

export default WelcomeCounter;
