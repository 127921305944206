import ga4 from "react-ga4";
import env from "../build.env.json";
import React from "react";
import { useLocation } from "react-router-dom";

const gaId = env.BUILD_ENV === "PROD" ? "G-S65D7Q1QLV" : "G-4L5FD344W6";

export const init = () =>
  ga4.initialize(gaId, {
    testMode: env.BUILD_ENV != "PROD",
  });

export const sendEvent = (name: string) =>
  ga4.event("screen_view", {
    app_name: "myApp",
    screen_name: name,
  });

export const sendPageview = (path: string) =>
  ga4.send({
    hitType: "pageview",
    page: path,
  });

export function useAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const path = location.pathname + location.search;
    sendPageview(path);
  }, [location]);
}

export default useAnalytics;
