import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../Base/BaseButton/BaseButton";
import BaseInput from "../Base/BaseInput/BaseInput";
import BaseReCaptchaText from "../Base/BaseReCaptchaText/BaseReCaptchaText";
import { getFunctions, httpsCallable } from "firebase/functions";
import rcv3Config from "../../configs/rcv3.config";
import UIContext from "../../contexts/ui.ctx";
import BaseToggle from "../Base/BaseToggle/BaseToggle";

import "./SignUpForm.css";

interface SignUpFormProps {}

const SignUpForm: React.FC<SignUpFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [newsletterPrompted, setNewsletterPrompted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const uiCtx = useContext(UIContext);
  const location = useLocation();

  const signUp = async () => {
    if (!email) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Veuillez entrer un email valide.",
      });
    }
    if (!password) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Veuillez entrer un mot de passe d'au moins 6 caractères.",
      });
    }
    if (password !== passwordConfirmation) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Les mots de passe ne correspondent pas.",
      });
    }
    if (!acceptNewsletter && !newsletterPrompted) {
      setNewsletterPrompted(true);
      return uiCtx.setAlertPrompt({
        type: "Pas de newsletter ? 🤔",
        message:
          "Vous pouvez facilement vous désabonner et la fréquence d'envoi est d'environ 1 fois par mois. 😊",
      });
    }
    setIsLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(rcv3Config().pk, { action: "submit" })
        .then(async (token: any) => {
          const restfulFunctions = getFunctions();
          const signUp = httpsCallable(restfulFunctions, "signUp");
          try {
            const signUpStatus = await signUp({
              email: email,
              password: password,
              rcv3Token: token,
              acceptNewsletter: acceptNewsletter,
            });
            console.log(signUpStatus);
            uiCtx.setAlertPrompt({
              type: "Succès",
              message:
                "Compte créé avec succès, il ne reste plus qu'à se connecter 😎 !",
            });
            navigate("/signin");
          } catch (err) {
            setIsLoading(false);
            uiCtx.setAlertPrompt({
              type: "Erreur",
              message:
                "Quelque chose ne tourne pas rond... 🥸 ! Rdv sur Discord pour demander de l'aide 👾 !",
            });
          }
        });
    });
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        signUp();
      }}
    >
      <div className="form-card__content__form">
        <BaseInput
          onValueChange={(val: string) => setEmail(val)}
          value={email}
          label="Email"
          type="email"
        />
        <BaseInput
          onValueChange={(val: string) => setPassword(val)}
          value={password}
          label="Mot de passe"
          type="password"
        />
        <BaseInput
          onValueChange={(val: string) => setPasswordConfirmation(val)}
          value={passwordConfirmation}
          label="Confirmation mot de passe"
          type="password"
        />
        <div className="newsletter-field">
          <BaseToggle
            value={acceptNewsletter}
            onClick={() => setAcceptNewsletter(!acceptNewsletter)}
          />
          <label>
            J'accepte de recevoir la newsletter du DevClub{" "}
            <span>(infos, projets, formations, etc.)</span>
          </label>
        </div>
      </div>

      <div className="form-card__content__control">
        <BaseButton
          name="S'inscrire"
          extended
          isLoading={isLoading}
          type="submit"
          onClick={() => {}}
        />
        <Link
          to={"/signin"}
          state={{ prevPath: location.pathname }}
          className="form-card__content__control__signin"
        >
          Se connecter
        </Link>
      </div>
      <div
        style={{
          padding: "0 1rem 1.5rem 1rem",
          textAlign: "center",
        }}
      >
        <BaseReCaptchaText />
      </div>
    </form>
  );
};

export default SignUpForm;
