const geoConfig = [
  { id: "00", name: "Autre" },
  { id: "01", name: "01 - Ain" },
  { id: "02", name: "02 - Aisne" },
  { id: "03", name: "03 - Allier" },
  { id: "04", name: "04 - Alpes-de-Haute-Provence" },
  { id: "05", name: "05 - Hautes-Alpes" },
  { id: "06", name: "06 - Alpes-Maritimes" },
  { id: "07", name: "07 - Ardèche" },
  { id: "08", name: "08 - Ardennes" },
  { id: "09", name: "09 - Ariège" },
  { id: "10", name: "10 - Aube" },
  { id: "11", name: "11 - Aude" },
  { id: "12", name: "12 - Aveyron" },
  { id: "13", name: "13 - Bouches-du-Rhône" },
  { id: "14", name: "14 - Calvados" },
  { id: "15", name: "15 - Cantal" },
  { id: "16", name: "16 - Charente" },
  { id: "17", name: "17 - Charente-Maritime" },
  { id: "18", name: "18 - Cher" },
  { id: "19", name: "19 - Corrèze" },
  { id: "21", name: "21 - Côte-d'Or" },
  { id: "22", name: "22 - Côtes-d'Armor" },
  { id: "23", name: "23 - Creuse" },
  { id: "24", name: "24 - Dordogne" },
  { id: "25", name: "25 - Doubs" },
  { id: "26", name: "26 - Drôme" },
  { id: "27", name: "27 - Eure" },
  { id: "28", name: "28 - Eure-et-Loir" },
  { id: "29", name: "29 - Finistère" },
  { id: "2A", name: "2A - Corse-du-Sud" },
  { id: "2B", name: "2B - Haute-Corse" },
  { id: "30", name: "30 - Gard" },
  { id: "31", name: "31 - Haute-Garonne" },
  { id: "32", name: "32 - Gers" },
  { id: "33", name: "33 - Gironde" },
  { id: "34", name: "34 - Hérault" },
  { id: "35", name: "35 - Ille-et-Vilaine" },
  { id: "36", name: "36 - Indre" },
  { id: "37", name: "37 - Indre-et-Loire" },
  { id: "38", name: "38 - Isère" },
  { id: "39", name: "39 - Jura" },
  { id: "40", name: "40 - Landes" },
  { id: "41", name: "41 - Loir-et-Cher" },
  { id: "42", name: "42 - Loire" },
  { id: "43", name: "43 - Haute-Loire" },
  { id: "44", name: "44 - Loire-Atlantique" },
  { id: "45", name: "45 - Loiret" },
  { id: "46", name: "46 - Lot" },
  { id: "47", name: "47 - Lot-et-Garonne" },
  { id: "48", name: "48 - Lozère" },
  { id: "49", name: "49 - Maine-et-Loire" },
  { id: "50", name: "50 - Manche" },
  { id: "51", name: "51 - Marne" },
  { id: "52", name: "52 - Haute-Marne" },
  { id: "53", name: "53 - Mayenne" },
  { id: "54", name: "54 - Meurthe-et-Moselle" },
  { id: "55", name: "55 - Meuse" },
  { id: "56", name: "56 - Morbihan" },
  { id: "57", name: "57 - Moselle" },
  { id: "58", name: "58 - Nièvre" },
  { id: "59", name: "59 - Nord" },
  { id: "60", name: "60 - Oise" },
  { id: "61", name: "61 - Orne" },
  { id: "62", name: "62 - Pas-de-Calais" },
  { id: "63", name: "63 - Puy-de-Dôme" },
  { id: "64", name: "64 - Pyrénées-Atlantiques" },
  { id: "65", name: "65 - Hautes-Pyrénées" },
  { id: "66", name: "66 - Pyrénées-Orientales" },
  { id: "67", name: "67 - Bas-Rhin" },
  { id: "68", name: "68 - Haut-Rhin" },
  { id: "69", name: "69 - Rhône" },
  { id: "70", name: "70 - Haute-Saône" },
  { id: "71", name: "71 - Saône-et-Loire" },
  { id: "72", name: "72 - Sarthe" },
  { id: "73", name: "73 - Savoie" },
  { id: "74", name: "74 - Haute-Savoie" },
  { id: "75", name: "75 - Paris" },
  { id: "76", name: "76 - Seine-Maritime" },
  { id: "77", name: "77 - Seine-et-Marne" },
  { id: "78", name: "78 - Yvelines" },
  { id: "79", name: "79 - Deux-Sèvres" },
  { id: "80", name: "80 - Somme" },
  { id: "81", name: "81 - Tarn" },
  { id: "82", name: "82 - Tarn-et-Garonne" },
  { id: "83", name: "83 - Var" },
  { id: "84", name: "84 - Vaucluse" },
  { id: "85", name: "85 - Vendée" },
  { id: "86", name: "86 - Vienne" },
  { id: "87", name: "87 - Haute-Vienne" },
  { id: "88", name: "88 - Vosges" },
  { id: "89", name: "89 - Yonne" },
  { id: "90", name: "90 - Territoire de Belfort" },
  { id: "91", name: "91 - Essonne" },
  { id: "92", name: "92 - Hauts-de-Seine" },
  { id: "93", name: "93 - Seine-Saint-Denis" },
  { id: "94", name: "94 - Val-de-Marne" },
  { id: "95", name: "95 - Val-d'Oise" },
];

export default geoConfig;
