import classNames from "classnames";
import "./GridState.css";

interface GridStateProps {
  state: "LOADING" | "SEARCHING" | "NO_RESULTS";
}

const GridState: React.FC<GridStateProps> = ({ state }) => {
  return (
    <div className="grid-state">
      <span
        className={classNames({
          "grid-state__text": true,
          "grid-state__text--breath": state !== "NO_RESULTS",
        })}
      >
        {state === "LOADING"
          ? "Chargement..."
          : state === "SEARCHING"
          ? "Recherche en cours..."
          : "Aucun résultat"}
      </span>
    </div>
  );
};

export default GridState;
