import { useEffect, useState } from "react";
import "./Mentorat.css";
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web";
import expert1 from "./../assets/mentorat/expert-1.svg";
import expert2 from "./../assets/mentorat/expert-2.svg";
import expert3 from "./../assets/mentorat/expert-3.svg";
import expert4 from "./../assets/mentorat/expert-4.svg";

import fbLogo from "./../assets/mentorat/probe-fb.png";
import ytLogo from "./../assets/mentorat/probe-yt.png";
import htmlLogo from "./../assets/mentorat/probe-html.png";
import twLogo from "./../assets/mentorat/probe-tw.png";
import jsLogo from "./../assets/mentorat/probe-js.png";

import probe from "./../assets/mentorat/probe-1.png";
import hugo2 from "./../assets/mentorat/hugo-2.png";

import advantage1 from "./../assets/mentorat/advantage-1.svg";
import advantage2 from "./../assets/mentorat/advantage-2.svg";
import advantage3 from "./../assets/mentorat/advantage-3.svg";
import advantage4 from "./../assets/mentorat/advantage-4.svg";

import testimonial1 from "./../assets/mentorat/testimonial-1.jpg";
import testimonial2 from "./../assets/mentorat/testimonial-2.jpg";
import testimonial3 from "./../assets/mentorat/testimonial-3.jpg";
import { useInView } from "react-intersection-observer";
import { getFunctions, httpsCallable } from "firebase/functions";
import classNames from "classnames";

import { useStripe } from "@stripe/react-stripe-js";
import React from "react";
import ConfirmPrompt from "../components/UI/ConfirmPrompt";
import { useSearchParams } from "react-router-dom";
import BaseLoadingCircle from "../components/Base/BaseLoadingCircle/BaseLoadingCircle";

interface MentoratProps {}

const Mentorat: React.FC<MentoratProps> = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [availableDays, setAvailableDays] = useState<Record<string, any>[]>([]);
  const [choosenHour, setChoosenHour] = useState<"eleven" | "thirteen" | null>(
    null
  );
  const [choosenDayId, setChoosenDayId] = useState<string | null>(null);

  const stripe = useStripe();

  const [confirmPromptVisible, setConfirmPromptVisible] = useState(false);

  useEffect(() => {
    console.log(availableDays);
  }, [availableDays]);

  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (queryParameters.get("success")?.valueOf() === "true") {
      setConfirmPromptVisible(true);
    }
  }, [queryParameters]);

  useEffect(() => {
    // Fetch the available days from the callable firebase function
    const restfulFunctions = getFunctions();
    const getCoachingAvailabilities = httpsCallable(
      restfulFunctions,
      "getCoachingAvailabilities"
    );
    getCoachingAvailabilities()
      .then((response) => {
        const responseData = response.data as {
          status: string;
          message: {
            id: string;
            date: any;
            booked: boolean;
          }[];
        };
        console.log(responseData.message);
        const availableDays = responseData.message.map((day) => {
          console.log(day);
          return {
            id: day.id,
            date: new Date(day.date._seconds * 1000),
            booked: day.booked,
          };
        });
        setAvailableDays(availableDays);
        setIsInit(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--current-scheme", "dark");
    document.documentElement.className = "dark";
    return () => {
      document.documentElement.style.setProperty("--current-scheme", "light");
      document.documentElement.className = "light";
    };
  }, []);

  async function bookingHandler() {
    if (choosenHour && choosenDayId && stripe && isLoading === false) {
      setIsLoading(true);
      const restfulFunctions = getFunctions();
      const createCheckoutSession = httpsCallable(
        restfulFunctions,
        "createCheckoutSession"
      );
      try {
        const response = (await createCheckoutSession({
          coachingId: choosenDayId,
        })) as Record<string, any>;
        await stripe.redirectToCheckout({ sessionId: response.data.id });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setIsLoading(false);
        // Handle error, e.g., show an error message to the user
      }
    }
  }

  function getTheFirstAvailability() {
    const theFirstAvailability = availableDays.find((day) => {
      return !day.booked;
    });
    // Transform it to a string like "Lun. 19 juil. 2021 à 19h"
    const theFirstAvailabilityString = theFirstAvailability
      ? theFirstAvailability.date
          .toLocaleDateString("fr-FR", {
            weekday: "short",
          })[0]
          .toUpperCase() +
        (
          theFirstAvailability.date.toLocaleDateString("fr-FR", {
            weekday: "short",
            day: "numeric",
            month: "short",
            year: "numeric",
          }) +
          " à " +
          (theFirstAvailability.id.includes("H11") ? "11h" : "13h")
        ).slice(1)
      : "Aucune session disponible...";

    return theFirstAvailabilityString;
  }

  const opacitySpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const introRef = useSpringRef();
  const titleRef = useSpringRef();
  const subtitleRef = useSpringRef();

  const introSpring = useSpring({
    from: {
      transform: "translate3d(0,25px,0)",
    },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: introRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });
  const titleSpring = useSpring({
    from: { transform: "translate3d(0,25px,0)" },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: titleRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });
  const subtitleSpring = useSpring({
    from: { transform: "translate3d(0,25px,0)" },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: subtitleRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });

  const descriptionRef = useSpringRef();
  const descriptionSpring = useSpring({
    from: { transform: "translate3d(-5%, 0px,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: descriptionRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });
  const picRef = useSpringRef();
  const picSpring = useSpring({
    from: { transform: "translate3d(5%, 0,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: picRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });
  const ctaRef = useSpringRef();
  const ctaSpring = useSpring({
    from: { transform: "translate3d(0, 5%,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    ref: ctaRef,
    config: { clamp: true, tension: 130, friction: 40, precision: 0.0001 },
  });

  useChain(
    [introRef, titleRef, subtitleRef, descriptionRef, picRef, ctaRef],
    [0, 0, 0, 0.1, 0.1, 0.2]
  );

  // Expert animations
  const [expertTitleRef, expertTitleRefInView] = useInView({
    threshold: 0.5, // Trigger the animation when the element is halfway into view
    triggerOnce: false,
  });
  const expertTitleSpring = useSpring({
    opacity: expertTitleRefInView ? 1 : 0, // Animate opacity from 0 to 1 when expertTitleRefInView is true
    transform: expertTitleRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)", // Animate translateY from 50px to 0 when inView is true
  });

  const [expertFirstItemRef, expertFirstItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [expertSecondItemRef, expertSecondItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [expertThirdItemRef, expertThirdItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [expertFourthItemRef, expertFourthItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const expertItemsRefs = [
    { ref: expertFirstItemRef, inView: expertFirstItemRefInView },
    { ref: expertSecondItemRef, inView: expertSecondItemRefInView },
    { ref: expertThirdItemRef, inView: expertThirdItemRefInView },
    { ref: expertFourthItemRef, inView: expertFourthItemRefInView },
  ];
  const expertFirstItemSpring = useSpring({
    opacity: expertFirstItemRefInView ? 1 : 0,
    transform: expertFirstItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const expertSecondItemSpring = useSpring({
    opacity: expertSecondItemRefInView ? 1 : 0,
    transform: expertSecondItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const expertThirdItemSpring = useSpring({
    opacity: expertThirdItemRefInView ? 1 : 0,
    transform: expertThirdItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const expertFourthItemSpring = useSpring({
    opacity: expertFourthItemRefInView ? 1 : 0,
    transform: expertFourthItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const expertItemsSprings = [
    expertFirstItemSpring,
    expertSecondItemSpring,
    expertThirdItemSpring,
    expertFourthItemSpring,
  ];

  // Probe animations
  const [probeTitleRef, probeTitleRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeTitleSpring = useSpring({
    opacity: probeTitleRefInView ? 1 : 0,
    transform: probeTitleRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)",
  });
  // > Screen Capture animation
  const [probeScreenshotRef, probeScreenshotRefInView] = useInView({
    threshold: 0.35,
    triggerOnce: false,
  });
  const probeScreenshotSpring = useSpring({
    opacity: probeScreenshotRefInView ? 1 : 0,
    transform: probeScreenshotRefInView ? "scale(1)" : "scale(0.5)",
  });
  // > Fb Logo Animation
  const [probeFbLogoRef, probeFbLogoRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeFbLogoSpring = useSpring({
    opacity: probeFbLogoRefInView ? 1 : 0,
  });
  // > Html Logo Animation
  const [probeHtmlLogoRef, probeHtmlLogoRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeHtmlLogoSpring = useSpring({
    opacity: probeHtmlLogoRefInView ? 1 : 0,
  });
  // > YT Logo Animation
  const [probeYtLogoRef, probeYtLogoRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeYtLogoSpring = useSpring({
    opacity: probeYtLogoRefInView ? 1 : 0,
  });
  // > HugPic Animation
  const [probeHugPicRef, probeHugPicRefInView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const probeHugPicSpring = useSpring({
    opacity: probeHugPicRefInView ? 1 : 0,
    transform: probeHugPicRefInView ? "translateX(0px)" : "translateX(-50px)",
  });
  // > Probe Description Animation
  const [probeDescriptionRef, probeDescriptionRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeDescriptionSpring = useSpring({
    opacity: probeDescriptionRefInView ? 1 : 0,
    transform: probeDescriptionRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)",
  });
  // > Probe TW logo
  const [probeTwLogoRef, probeTwLogoRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeTwLogoSpring = useSpring({
    opacity: probeTwLogoRefInView ? 1 : 0,
  });
  // > Probe JS logo
  const [probeJsLogoRef, probeJsLogoRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const probeJsLogoSpring = useSpring({
    opacity: probeJsLogoRefInView ? 1 : 0,
  });

  // Advantages animations
  const [advantagesTitleRef, advantagesTitleRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const advantagesTitleSpring = useSpring({
    opacity: advantagesTitleRefInView ? 1 : 0,
    transform: advantagesTitleRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)",
  });

  const [advantagesFirstItemRef, advantagesFirstItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [advantagesSecondItemRef, advantagesSecondItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [advantagesThirdItemRef, advantagesThirdItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [advantagesFourthItemRef, advantagesFourthItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const advantagesItemsRefs = [
    { ref: advantagesFirstItemRef, inView: advantagesFirstItemRefInView },
    { ref: advantagesSecondItemRef, inView: advantagesSecondItemRefInView },
    { ref: advantagesThirdItemRef, inView: advantagesThirdItemRefInView },
    { ref: advantagesFourthItemRef, inView: advantagesFourthItemRefInView },
  ];
  const advantagesFirstItemSpring = useSpring({
    opacity: advantagesFirstItemRefInView ? 1 : 0,
    transform: advantagesFirstItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const advantagesSecondItemSpring = useSpring({
    opacity: advantagesSecondItemRefInView ? 1 : 0,
    transform: advantagesSecondItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const advantagesThirdItemSpring = useSpring({
    opacity: advantagesThirdItemRefInView ? 1 : 0,
    transform: advantagesThirdItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const advantagesFourthItemSpring = useSpring({
    opacity: advantagesFourthItemRefInView ? 1 : 0,
    transform: advantagesFourthItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const advantagesItemsSprings = [
    advantagesFirstItemSpring,
    advantagesSecondItemSpring,
    advantagesThirdItemSpring,
    advantagesFourthItemSpring,
  ];

  // > Testimonials animations
  const [testimonialsTitleRef, testimonialsTitleRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const testimonialsTitleSpring = useSpring({
    opacity: testimonialsTitleRefInView ? 1 : 0,
    transform: testimonialsTitleRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)",
  });
  const [testimonialsFirstItemRef, testimonialsFirstItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [testimonialsSecondItemRef, testimonialsSecondItemRefInView] =
    useInView({
      threshold: 0.5,
      triggerOnce: false,
    });
  const [testimonialsThirdItemRef, testimonialsThirdItemRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const testimonialsItemsRefs = [
    { ref: testimonialsFirstItemRef, inView: testimonialsFirstItemRefInView },
    { ref: testimonialsSecondItemRef, inView: testimonialsSecondItemRefInView },
    { ref: testimonialsThirdItemRef, inView: testimonialsThirdItemRefInView },
  ];
  const testimonialsFirstItemSpring = useSpring({
    opacity: testimonialsFirstItemRefInView ? 1 : 0,
    transform: testimonialsFirstItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const testimonialsSecondItemSpring = useSpring({
    opacity: testimonialsSecondItemRefInView ? 1 : 0,
    transform: testimonialsSecondItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const testimonialsThirdItemSpring = useSpring({
    opacity: testimonialsThirdItemRefInView ? 1 : 0,
    transform: testimonialsThirdItemRefInView ? "scale(1)" : "scale(0.75)",
  });
  const testimonialsItemsSprings = [
    testimonialsFirstItemSpring,
    testimonialsSecondItemSpring,
    testimonialsThirdItemSpring,
  ];

  // > Service animations
  const [serviceTitleRef, serviceTitleRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const serviceTitleSpring = useSpring({
    opacity: serviceTitleRefInView ? 1 : 0,
    transform: serviceTitleRefInView
      ? "translate3d(0, 0, 0)"
      : "translate3d(0, 20px, 0)",
  });
  const [serviceMainRef, serviceMainRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  // const serviceMainSpring = useSpring({
  //   opacity: serviceMainRefInView ? 1 : 0,
  //   transform: serviceMainRefInView ? "scale(1)" : "scale(0.75)",
  // });
  const [serviceMoreFirstRef, serviceMoreFirstRefInView] = useInView({
    threshold: 0.25,
    triggerOnce: false,
  });
  const [serviceMoreSecondRef, serviceMoreSecondRefInView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const [serviceMoreThirdRef, serviceMoreThirdRefInView] = useInView({
    threshold: 0.8,
    triggerOnce: false,
  });
  const serviceMoreRefs = [
    { ref: serviceMoreFirstRef, inView: serviceMoreFirstRefInView },
    { ref: serviceMoreSecondRef, inView: serviceMoreSecondRefInView },
    { ref: serviceMoreThirdRef, inView: serviceMoreThirdRefInView },
  ];
  const serviceMoreFirstSpring = useSpring({
    opacity: serviceMoreFirstRefInView ? 1 : 0,
    transform: serviceMoreFirstRefInView ? "scale(1)" : "scale(0.75)",
  });
  const serviceMoreSecondSpring = useSpring({
    opacity: serviceMoreSecondRefInView ? 1 : 0,
    transform: serviceMoreSecondRefInView ? "scale(1)" : "scale(0.75)",
  });
  const serviceMoreThirdSpring = useSpring({
    opacity: serviceMoreThirdRefInView ? 1 : 0,
    transform: serviceMoreThirdRefInView ? "scale(1)" : "scale(0.75)",
  });
  const serviceMoreSprings = [
    serviceMoreFirstSpring,
    serviceMoreSecondSpring,
    serviceMoreThirdSpring,
  ];

  const calendarRef = React.useRef<HTMLInputElement>(null);

  const handleQuickReservation = () => {
    const theFirstAvailability = availableDays.find((day) => {
      return !day.booked;
    });
    if (!theFirstAvailability) return;
    setChoosenDayId(theFirstAvailability.id);
    setChoosenHour(
      theFirstAvailability.id.split("-")[3] === "H11" ? "eleven" : "thirteen"
    );
    calendarRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="mentorat">
      {confirmPromptVisible && (
        <ConfirmPrompt onClose={() => setConfirmPromptVisible(false)} />
      )}
      <div className="mentorat_wide-bg" />
      <div className="mentorat_hero">
        <animated.div
          style={picSpring}
          className="mentorat_hero_pic"
        ></animated.div>
        <animated.div
          style={picSpring}
          className="mentorat_hero_pic--code"
        ></animated.div>
        <animated.span
          style={{ ...introSpring, ...opacitySpring }}
          className="mentorat_hero_intro"
        >
          LE MENTORAT DEVCLUB
        </animated.span>
        <animated.span
          style={{ ...titleSpring, ...opacitySpring }}
          className="mentorat_hero_title"
        >
          Besoin de conseils personnalisés ?
        </animated.span>
        <animated.span
          style={{ ...subtitleSpring, ...opacitySpring }}
          className="mentorat_hero_subtitle"
        >
          Réservez dès maintenant une séance de mentorat à mes côtés !
        </animated.span>
        <section className="mentorat_hero_description-section">
          <animated.div
            style={descriptionSpring}
            className="mentorat_hero_description-container"
          >
            <p className="mentorat_hero_description">
              Je suis <strong>Hugo Taschet</strong>, développeur web et mobile
              indépendant et créateur de la chaîne YouTube DevClub.{" "}
              <strong>Depuis 2020</strong>, j’aide des <strong>milliers</strong>{" "}
              de développeurs à voir plus clair dans la jungle du développement.
              C’est maintenant <strong>votre tour</strong> !
            </p>
          </animated.div>
        </section>
        <section className="mentorat_hero_cta-section">
          <animated.div
            style={ctaSpring}
            className="mentorat_hero_cta-container"
          >
            <div className="mentorat_hero_cta">
              <span className="mentorat_hero_cta_label">
                Prochaine session disponible :
              </span>
              <div className="mentorat_hero_cta_next-session-container">
                <div className="mentorat_hero_cta_next-session">
                  {isInit ? (
                    getTheFirstAvailability()
                  ) : (
                    <BaseLoadingCircle mini />
                  )}
                </div>
                <button
                  className="mentorat_hero_cta_button"
                  onClick={() => handleQuickReservation()}
                >
                  Réserver cette séance
                </button>
              </div>
              <span className="mentorat_hero_cta_label mentorat_hero_cta_label--secondary">
                Ou :
              </span>
              <button
                className="mentorat_hero_cta_button mentorat_hero_cta_button--secondary"
                onClick={() =>
                  calendarRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Voir tous les créneaux disponibles
              </button>
            </div>
          </animated.div>
        </section>
      </div>
      <div className="mentorat_expert">
        {/* <div className="mentorat_expert_color-bubble bubble-1" />
        <div className="mentorat_expert_color-bubble bubble-2" /> */}
        <animated.span
          style={expertTitleSpring}
          ref={expertTitleRef}
          className="mentorat_expert_title"
        >
          Mon expertise à votre service !
        </animated.span>
        <div className="mentorat_expert_content">
          {[
            {
              title: "Orientation",
              content:
                "Vous hésitez à vous lancer dans le développement ou à choisir une voie ? Je vous aide à prendre la bonne décision.",
              image: expert1,
            },
            {
              title: "Profile Review",
              content:
                "Vous ne trouvez pas de job ? C’est peut-être que quelque chose coince...trouvons ensemble les solutions !",
              image: expert2,
            },
            {
              title: "Cours Particuliers",
              content:
                "En formation ou en autodidacte, vous bloquez sur une ou plusieurs notions ? Je suis là pour vous aider !",
              image: expert3,
            },
            {
              title: "Accompagnement",
              content:
                "Vous avez un projet personnel d’application ? Je vous aide à le réaliser efficacement et à atteindre vos objectifs.",
              image: expert4,
            },
          ].map((item, index) => {
            return (
              <animated.div
                className={`mentorat_expert_item mentorat_expert_item--${index}`}
                key={"expert-item-" + index}
                ref={expertItemsRefs[index].ref}
                style={expertItemsSprings[index]}
              >
                <img src={item.image} alt={item.title} />
                <div className="expert-item-content">
                  <span>{item.title}</span>
                  <p>{item.content}</p>
                </div>
              </animated.div>
            );
          })}
        </div>
      </div>
      <div className="mentorat_probe">
        <animated.span
          className="mentorat_probe_title"
          ref={probeTitleRef}
          style={probeTitleSpring}
        >
          Déjà plus de 25.000 abonnés me font confiance sur YouTube.
        </animated.span>
        <div className="mentorat_probe_illustrations">
          <animated.img
            className="mentorat_probe_illustrations--fb"
            src={fbLogo}
            ref={probeFbLogoRef}
            style={probeFbLogoSpring}
          />
          <animated.img
            className="mentorat_probe_illustrations--probe"
            src={probe}
            ref={probeScreenshotRef}
            style={probeScreenshotSpring}
          />
          <animated.img
            className="mentorat_probe_illustrations--html"
            src={htmlLogo}
            ref={probeHtmlLogoRef}
            style={probeHtmlLogoSpring}
          />
          <animated.img
            className="mentorat_probe_illustrations--yt"
            src={ytLogo}
            ref={probeYtLogoRef}
            style={probeYtLogoSpring}
          />
        </div>
        <animated.div
          className="mentorat_probe_content"
          ref={probeDescriptionRef}
          style={probeDescriptionSpring}
        >
          <span>Un regard différent sur le métier</span>
          <p>
            Depuis plusieurs années, mon objectif est de partager mes
            connaissances et de vous fournir les meilleurs conseils pour vous
            aider à devenir un développeur accompli ou à vous lancer dans ce
            domaine passionnant. Je ne peux que remercier ma communauté pour la
            confiance qu’elle me porte.
          </p>
        </animated.div>
        <div className="mentorat_probe_illustrations-bottom">
          <animated.img
            className="mentorat_probe_illustrations-bottom--hugo"
            src={hugo2}
            ref={probeHugPicRef}
            style={probeHugPicSpring}
          />
          <animated.img
            className="mentorat_probe_illustrations-bottom--tw"
            src={twLogo}
            style={probeTwLogoSpring}
            ref={probeTwLogoRef}
          />
          <animated.img
            className="mentorat_probe_illustrations-bottom--js"
            src={jsLogo}
            style={probeJsLogoSpring}
            ref={probeJsLogoRef}
          />
        </div>
      </div>
      <div className="mentorat_advantages">
        {/* <div className="mentorat_expert_color-bubble bubble-1" />
        <div className="mentorat_expert_color-bubble bubble-2" /> */}
        <animated.span
          className="mentorat_advantages_title"
          ref={advantagesTitleRef}
          style={advantagesTitleSpring}
        >
          Les avantages du mentorat
        </animated.span>
        <div className="mentorat_advantages_content">
          {[
            {
              title: "Un rendez-vous sur mesure",
              content:
                "Votre profil est unique et parfois, mes vidéos ne suffisent pas. Je peux étudier votre cas pour vous offrir des conseils personnalisés en orientation professionnelle, formation, projets personnels ou concepts de programmation. Vous décidez ce dont vous avez besoin.",
              icon: advantage1,
            },
            {
              title: "Les conseils d’un professionnel",
              content:
                "Passionné de code depuis l’enfance et développeur professionnel depuis 2018, j’ai un parcours atypique. Ainsi, avec empathie, je vous aiderai à comprendre les concepts les plus complexes du code et à toujours viser l’excellence !",
              icon: advantage2,
            },
            {
              title: "Un accompagnement à la demande",
              content:
                "Le programme de mentorat est flexible : rendez-vous unique ou suivi à long terme, vous déterminez la fréquence et les objectifs. Que vous soyez débutant ou que vous souhaitiez développer un projet, je m’adapterai à vos besoins. Et même avec un accompagnement quotidien si vous le souhaitez !",
              icon: advantage3,
            },
            {
              title: "Un réel avantage compétitif",
              content:
                "Le métier de développeur est exigeant et il est facile de se sentir submergé. Pourtant, un simple effort suffit souvent à se démarquer, surtout si vous n'avez pas encore de portfolio. La qualité de votre travail et de votre code est essentielle pour réussir. Exploitez votre potentiel et dites adieu au syndrome de l'imposteur.",
              icon: advantage4,
            },
          ].map((item, index) => {
            return (
              <animated.div
                className={`mentorat_advantages_item mentorat_advantages_item--${index}`}
                key={"advantage-item-" + index}
                style={advantagesItemsSprings[index]}
                ref={advantagesItemsRefs[index].ref}
              >
                <img src={item.icon} alt="advantage_decoration" />
                <span>{item.title}</span>
                <p>{item.content}</p>
              </animated.div>
            );
          })}
        </div>
      </div>
      <div className="mentorat_testimonials">
        <animated.span
          className="mentorat_testimonials_title"
          ref={testimonialsTitleRef}
          style={testimonialsTitleSpring}
        >
          Je suis devenu leur mentor
        </animated.span>
        <div className="mentorat_testimonials_list">
          {[
            {
              name: "Jason S.",
              job: "Développeur Web",
              content:
                "“Hugo est comme le grand frère développeur qu’on voudrait avoir, surtout quand on débute, et qui sait nous mettre sur la bonne voie avec peu de mots. <br><br>Sa gentillesse et sa franchise n’ont d’égal que son expertise et son professionnalisme. Je le remercie vivement pour ses conseils avisés.”",
              image: testimonial1,
              linkedInUrl: "https://www.linkedin.com/in/jason-suarez/",
            },
            {
              name: "Lior L.",
              job: "Développeuse Web",
              content:
                "“J'ai récemment pu bénéficier du mentorat de Hugo Taschet concernant React Native, un framework avec lequel je n'étais pas familier. Durant notre échange, j'ai posé des questions sur l'architecture du projet, les bonnes pratiques et les tests unitaires.<br><br>Hugo a su répondre à toutes mes interrogations de manière claire et concise, en utilisant des schémas pour faciliter la compréhension. Il m'a même présenté un exemple concret de projet qu'il avait réalisé. Il est évident que Hugo maîtrise son sujet et excelle dans l'application du clean code.<br><br>Cette expérience de mentorat a été extrêmement enrichissante et je recommande vivement Hugo à quiconque souhaite approfondir ses connaissances en web & mobile. Par contre, préparez vous, après le call, il y a du boulot ! 😉”",
              image: testimonial3,
              linkedInUrl: "https://www.linkedin.com/in/liorlevy69/",
            },
            {
              name: "Abel R.",
              job: "Développeur Web",
              content:
                "“Mon expérience de mentorat avec Hugo s'est très bien passée. Il a su répondre à mes besoins et à mes questions sans tourner autour du pot, en me donnant des exemples clairs et précis de sa vie professionnelle et de son expérience. <br><br> Il a pris le temps de bien m'expliquer les choses, point par point. De mon point de vue, c'est un très bon mentor qui se préoccupe des problèmes que l'on peut rencontrer.“",
              image: testimonial2,
              linkedInUrl: "https://www.linkedin.com/in/ab-rahmani/",
            },
          ].map((item, index) => {
            return (
              <animated.div
                className={`mentorat_testimonials_item mentorat_testimonials_item--${index}`}
                key={"testimonial-item-" + index}
                style={testimonialsItemsSprings[index]}
                ref={testimonialsItemsRefs[index].ref}
              >
                <div className="mentorat_testimonials_item-header">
                  <img src={item.image} alt="profile picture" />
                  <div className="mentorat_testimonials_item-header-description">
                    <span>{item.name}</span>
                    <span>
                      <a href={item.linkedInUrl}>Profil LinkedIn</a>
                    </span>
                    {/* <span>{item.job}</span> */}
                  </div>
                </div>
                <div className="mentorat_testimonials_item-content">
                  <p dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </animated.div>
            );
          })}
        </div>
        <div ref={calendarRef} />
      </div>
      <div className="mentorat_service">
        <animated.span
          className="mentorat_service_title"
          ref={serviceTitleRef}
          style={serviceTitleSpring}
        >
          Réserver une séance
        </animated.span>
        <div className="mentorat_service_pricing-calendar-container">
          <div className="pricing-calendar_content">
            <div className="pricing-calendar_pricing">
              <span className="pricing-calendar_pricing-intro">
                <span>LE MENTORAT DEVCLUB</span>
                <span>OFFRE DE LANCEMENT</span>
              </span>
              <div>
                <span className="pricing-calendar_title">
                  La séance de mentorat
                </span>
                <span className="pricing-calendar_price">
                  89€<span>TTC</span>
                </span>
                <span className="pricing-calendar_duration">
                  Session de 45 minutes
                </span>
              </div>
              <div className="pricing-calendar_bulletpoints">
                <span>Rendez-vous privé via Google Meet</span>
                <span>Orientation et conseils de carrière</span>
                <span>Cours particuliers et suivi de formation</span>
                <span>Aide au profil et à la recherche d'emploi</span>
                <span>Accompagnement projets personnels</span>
                <span>Invitation au Discord Privé</span>
              </div>
            </div>
            <div className="pricing-calendar_divider" />
            <div className="pricing-calendar_calendar">
              <span
                className="pricing-calendar_calendar-title"
                style={{
                  pointerEvents: !isLoading ? "auto" : "none",
                  opacity: !isLoading ? 1 : 0.5,
                }}
              >
                1. Je choisi l'heure qui me convient :{" "}
              </span>
              <div
                className="pricing-calendar_calendar_hours"
                style={{
                  pointerEvents: !isLoading ? "auto" : "none",
                  opacity: !isLoading ? 1 : 0.5,
                }}
              >
                <div
                  className={classNames({
                    "pricing-calendar_calendar_hours--selected":
                      choosenHour === "eleven",
                  })}
                  onClick={() => {
                    if (choosenHour !== "eleven") {
                      setChoosenHour("eleven");
                      setChoosenDayId(null);
                    } else {
                      setChoosenHour(null);
                      setChoosenDayId(null);
                    }
                  }}
                >
                  11h
                </div>
                <div
                  className={classNames({
                    "pricing-calendar_calendar_hours--selected":
                      choosenHour === "thirteen",
                  })}
                  onClick={() => {
                    if (choosenHour !== "thirteen") {
                      setChoosenHour("thirteen");
                      setChoosenDayId(null);
                    } else {
                      setChoosenHour(null);
                      setChoosenDayId(null);
                    }
                  }}
                >
                  13h
                </div>
              </div>
              <span
                style={{
                  opacity: isLoading ? 0.5 : choosenHour ? 1 : 0.2,
                  pointerEvents: !isLoading ? "auto" : "none",
                }}
                className="pricing-calendar_calendar-title"
              >
                2. Je choisi le jour qui me convient :{" "}
              </span>
              {isInit ? (
                <div
                  style={{
                    opacity: isLoading ? 0.5 : choosenHour ? 1 : 0.2,
                    pointerEvents: isLoading
                      ? "none"
                      : choosenHour
                      ? "auto"
                      : "none",
                  }}
                  className="pricing-calendar_calendar-days"
                >
                  {availableDays
                    .filter((day) => {
                      if (choosenHour === "eleven") {
                        return day.id.includes("H11");
                      } else if (choosenHour === "thirteen") {
                        return day.id.includes("H13");
                      } else {
                        return day.id.includes("H11"); // Safari bug fix needed
                      }
                    })
                    .map((day, index) => {
                      return (
                        <div
                          className={classNames({
                            "pricing-calendar_calendar_day": true,
                            "pricing-calendar_calendar_day--selected":
                              choosenDayId === day.id,
                            "pricing-calendar_calendar_day--disabled":
                              day.booked && choosenHour,
                          })}
                          key={day.id}
                          onClick={() => {
                            console.log(day);
                            if (!day.booked)
                              if (choosenDayId !== day.id) {
                                setChoosenDayId(day.id);
                              } else {
                                setChoosenDayId(null);
                              }
                          }}
                        >
                          <span>
                            {day.date
                              .toLocaleString("fr-FR", {
                                weekday: "short",
                              })
                              .toUpperCase()
                              .replace(".", "")}
                          </span>
                          <span>{day.date.getDate()}</span>
                          <span>
                            {day.date
                              .toLocaleString("fr-FR", {
                                month: "short",
                              })
                              .toUpperCase()
                              .replace(".", "")}
                          </span>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div
                  style={{
                    height: "286px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseLoadingCircle />
                </div>
              )}

              <div className="pricing-calendar_submit-control">
                <button
                  className="pricing-calendar_submit-control_button"
                  disabled={
                    choosenHour && choosenDayId && !isLoading ? false : true
                  }
                  onClick={bookingHandler}
                  style={{
                    pointerEvents:
                      choosenHour && choosenDayId && !isLoading
                        ? "auto"
                        : "none",
                  }}
                >
                  {!isLoading ? (
                    "Réserver cette séance"
                  ) : (
                    <BaseLoadingCircle mini color="white" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mentorat_service_more">
          <animated.span
            ref={serviceMoreFirstRef}
            style={serviceMoreFirstSpring}
          >
            Aucun créneau ne vous convient ?
          </animated.span>
          <animated.span
            ref={serviceMoreSecondRef}
            style={serviceMoreSecondSpring}
          >
            Besoin d'un suivi sur plusieurs séances ?
          </animated.span>
          <animated.span
            ref={serviceMoreThirdRef}
            style={serviceMoreThirdSpring}
          >
            Contactez-moi directement à l’adresse{" "}
            <a href="mailto:mentorat.devclub@gmail.com">
              mentorat.devclub@gmail.com
            </a>{" "}
            et nous trouverons ensemble les créneaux les plus adaptés !
          </animated.span>
        </div>
      </div>
      <div className="mentorat_policy-container">
        <div className="mentorat_policy">
          <div className="mentorat_policy_col">
            <span className="mentorat_policy_col_title">
              Conditions générales de vente
            </span>
            {[
              {
                title: "1. Champ d’application et objet du contrat",
                content:
                  "Les présentes conditions générales de vente s'appliquent à toutes les prestations de mentorat/coaching proposées par la société SUDOCOOL, domiciliée au 6 Rue d’Armaillé - 75017 Paris, à ses clients. Elles régissent l'ensemble des relations entre SUDOCOOL et ses clients, à l'exclusion de toutes autres conditions, sauf accord exprès et préalable de la part de SUDOCOOL. Les clients reconnaissent avoir pris connaissance des présentes conditions générales de vente avant de passer commande. La validation de la commande implique donc l'adhésion entière et sans réserve du client aux présentes conditions générales de vente.",
              },
              {
                title: "2. Réservation d’une séance de mentorat",
                content:
                  "Les réservations peuvent être effectuées via le site internet devclub.fr ou par l’intermédiaire de l’adresse email mentorat.devclub@gmail.com. Toute commande implique l'acceptation des prix et des prestations proposées. La confirmation de la commande est effectuée par réception d’une facture à l’adresse email renseignée. Les informations communiquées par le client lors de la passation de commande engagent celui-ci. En cas d'erreur dans le libellé des coordonnées du destinataire, SUDOCOOL ne saurait être tenue responsable de l'impossibilité dans laquelle elle pourrait être de livrer la prestation.",
              },
              {
                title: "3. Prix et paiement",
                content:
                  "Les prix des prestations de mentorat/coaching sont indiqués en euros et sont ceux en vigueur au moment de la commande. Les prix peuvent être modifiés à tout moment par SUDOCOOL, mais les prestations seront facturées au tarif en vigueur au moment de la commande. Le paiement des prestations peut être effectué par tous les moyens disponibles via notre prestataire de paiement Stripe. Le paiement doit être effectué au moment de la commande, avant le début de la prestation, sauf accord préalable de la part de SUDOCOOL. Tout défaut de paiement entraîne la résolution immédiate de la commande.",
              },
              {
                title: "4. Annulation & Droit de rétractation",
                content:
                  "Conformément aux dispositions légales en vigueur, les clients bénéficient d'un délai de rétractation de quatorze (14) jours à compter de la validation de la commande pour annuler leur achat sans avoir à justifier de motif ni à payer de pénalités. Toutefois, si la prestation de mentorat/coaching a commencé avant la fin du délai de rétractation et avec l'accord du client, celui-ci renonce à son droit de rétractation. Pour exercer son droit de rétractation, le client doit notifier sa décision par email à SUDOCOOL. Le remboursement sera effectué dans un délai de quatorze (14) jours à compter de la réception de la demande de rétractation, sous réserve que la prestation n'ait pas déjà commencé.",
              },
            ].map((item, index) => {
              return (
                <div
                  className="mentorat_policy_col_item"
                  key={"policy-item-" + item.title}
                >
                  <span className="mentorat_policy_col_item_title">
                    {item.title}
                  </span>
                  <span className="mentorat_policy_col_item_content">
                    {item.content}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="mentorat_policy_col">
            {[
              {
                title: "5. Garantie et responsabilité",
                content:
                  "SUDOCOOL s'engage à fournir les prestations de mentorat/coaching avec diligence et compétence, en respectant les règles de l'art et les bonnes pratiques de la profession. Toutefois, SUDOCOOL ne peut être tenue pour responsable des conséquences directes ou indirectes liées à l'utilisation des prestations de mentorat/coaching, que ce soit au niveau des résultats obtenus, des décisions prises ou des actions entreprises par les clients. SUDOCOOL ne peut pas non plus être tenue pour responsable des retards ou des défauts d'exécution de ses prestations en cas de force majeure, telle que définie par la jurisprudence française.",
              },
              {
                title: "6. Protection des données personnelles",
                content:
                  "SUDOCOOL s'engage à respecter la réglementation en vigueur en matière de protection des données personnelles. Les données personnelles collectées auprès des clients sont nécessaires à la gestion des commandes et à la fourniture des prestations de mentorat/coaching. Elles sont conservées de manière confidentielle et ne sont pas communiquées à des tiers, sauf en cas de demande judiciaire ou réglementaire. Les clients disposent d'un droit d'accès, de rectification, d'opposition et de suppression de leurs données personnelles. Ils peuvent exercer ces droits en contactant SUDOCOOL par email à mentorat.devclub@gmail.com.",
              },
              {
                title: "7. Propriété intellectuelle",
                content:
                  "Les prestations de mentorat/coaching et l'ensemble des documents, supports, logiciels, méthodologies, concepts, techniques et savoir-faire utilisés par SUDOCOOL dans le cadre de ses prestations sont protégés par les lois sur la propriété intellectuelle. Ils demeurent la propriété exclusive de SUDOCOOL. Les clients s'engagent à ne pas reproduire, modifier, adapter, traduire, représenter ou exploiter à des fins commerciales tout ou partie des prestations, documents, supports, logiciels, méthodologies, concepts, techniques et savoir-faire de SUDOCOOL, sans son accord préalable et écrit.",
              },
              {
                title: "8. Résolution des litiges et loi applicable",
                content:
                  "En cas de litige entre SUDOCOOL et un client, les parties s'engagent à rechercher une solution amiable. À défaut de solution amiable, le litige sera porté devant les tribunaux compétents du lieu du siège social de SUDOCOOL. Les présentes conditions générales de vente sont régies par le droit français. Tout différend relatif à leur interprétation ou à leur exécution sera soumis aux tribunaux français compétents.",
              },
              {
                title: "9. Modifications des conditions générales de vente",
                content:
                  "SUDOCOOL se réserve le droit de modifier à tout moment les présentes conditions générales de vente. Les nouvelles conditions générales de vente seront applicables aux commandes passées après leur mise en ligne sur le site internet devclub.fr. Les clients sont invités à consulter régulièrement les conditions générales de vente en vigueur pour être informés des éventuelles modifications.",
              },
            ].map((item, index) => {
              return (
                <div
                  className="mentorat_policy_col_item"
                  key={"policy-item-" + item.title}
                >
                  <span className="mentorat_policy_col_item_title">
                    {item.title}
                  </span>
                  <span className="mentorat_policy_col_item_content">
                    {item.content}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <span className="copyright">
        Tous droits réservés - Sudocool / DevClub - 2023
      </span>
    </div>
  );
};

export default Mentorat;
