import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import BaseIcon from "../BaseIcon/BaseIcon";
import "./BaseImageInput.css";

interface ContainerProps {
  presetValue?: string;
  value: File;
  onImageSelected: Function;
}

const BaseImageInput: React.FC<ContainerProps> = (props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPreviewImage(URL.createObjectURL(props.value));
  }, [props.value]);

  return (
    <div className="base-image-input" data-testid="base-image-input">
      <input
        className="base-image-input__hidden-input"
        type="file"
        ref={hiddenInputRef}
        onChange={(event) => {
          if (event?.target?.files?.length) {
            props.onImageSelected(event.target.files[0]);
          } else {
            return;
          }
        }}
      />
      <div
        className="base-image-input__visualizer"
        style={{ backgroundImage: `url(${previewImage})` }}
      ></div>
      <div
        className={classNames({
          "base-image-input__input-container": true,
          "base-image-input__input-container--drag-over": isDragOver,
        })}
        onDrop={(event) => {
          event.preventDefault();
          props.onImageSelected(event.dataTransfer.files[0]);
          setIsDragOver(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
      >
        <BaseIcon icon={faFileImage} size="32px" />
        <span className="base-image-input__input-container__text">
          <span
            className="base-image-input__input-container__text__action"
            onClick={() => hiddenInputRef.current?.click()}
          >
            Charger une image
          </span>{" "}
          ou déposez la ici
        </span>
      </div>
    </div>
  );
};

export default BaseImageInput;
