import "./MouseInfo.css";
import useMousePosition from "../../hooks/useMousePosition";
import useWindowSize from "../../hooks/useWindowSize";
import { useContext, useEffect, useState } from "react";
import UIContext from "../../contexts/ui.ctx";
interface MouseInfoProps {}

const MouseInfo: React.FC<MouseInfoProps> = (props) => {
  const [infoPositionInverted, setInfoPositionInverted] = useState(false);

  const mousePosition = useMousePosition(8);
  const windowWidth = useWindowSize();
  const uiCtx = useContext(UIContext);
  useEffect(() => {
    if (mousePosition.mouseX && mousePosition.mouseX > windowWidth.width / 2) {
      if (!infoPositionInverted) {
        return setInfoPositionInverted(true);
      }
    } else {
      if (infoPositionInverted) {
        return setInfoPositionInverted(false);
      }
    }
  }, [windowWidth, mousePosition, infoPositionInverted]);

  return (
    <div
      className="mouse-info"
      style={{
        transform: `translateY(calc(${
          mousePosition.mouseY
        }px - 100%)) translateX(calc(${mousePosition.mouseX}px - ${
          infoPositionInverted ? "100%" : "0rem"
        }))`,
      }}
    >
      {uiCtx.mouseInfo}
    </div>
  );
};

export default MouseInfo;
