import "./ProfileEditor.css";
import MemberCard from "./MemberCard";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import $firestore, {
  $firebaseAuth,
  $firebaseStorage,
} from "../../services/app/firebase-service";
import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import BaseImageInput from "../Base/BaseImageInput/BaseImageInput";
import BaseButton from "../Base/BaseButton/BaseButton";
import {
  faChevronDown,
  faChevronUp,
  faClockRotateLeft,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import BaseIcon from "../Base/BaseIcon/BaseIcon";
import BaseInput from "../Base/BaseInput/BaseInput";
import geoConfig from "../../configs/geo.config";
import classNames from "classnames";
import BaseTextArea from "../Base/BaseTextArea/BaseTextArea";
import skills from "../../configs/skills.config";
import SkillIcon from "./SkillIcon";
import UIContext from "../../contexts/ui.ctx";
import resizeFile from "../../services/app/image-resizer";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import logoDevolved from "../../assets/logo/devolved.png";
import logoDevStarter from "../../assets/logo/devstarter.png";
import BaseToggle from "../Base/BaseToggle/BaseToggle";

interface ProfileEditorProps {}

// TODO VALIDATION ON CLIENT

const ProfileEditor: React.FC<ProfileEditorProps> = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mainSkill, setMainSkill] = useState("");
  const [description, setDescription] = useState("");
  const [startingYear, setStartingYear] = useState(2020);
  const [status, setStatus] = useState("WORKER");

  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const [skillsIds, setSkillsIds] = useState([] as string[]);
  const [subSkillsIds, setSubSkillsIds] = useState([] as string[]);
  const [linkedinId, setLinkedinId] = useState("");
  const [githubId, setGithubId] = useState("");
  const [portfolioUrl, setPortfolioUrl] = useState("");
  const [rewardsIds, setRewardsIds] = useState([] as string[]);
  const [geoId, setGeoId] = useState("");
  const [pendingProfilePictureFile, setPendingProfilePictureFile] = useState(
    new File([], "")
  );
  const [currentPictureUrl, setCurrentPictureUrl] = useState("");
  const [hasMinimalProfileCompletion, setHasMinimalProfileCompletion] =
    useState(false);
  const [contestantExperience, setContestantExperience] = useState(0);

  const [interestsPanelIsOpen, setInterestsPanelIsOpen] = useState(false);

  const navigate = useNavigate();
  const uiCtx = useContext(UIContext);
  const [devReviewIsEnabled, setDevReviewIsEnabled] = useState(false);
  const [devolvedInterestIsEnabled, setDevolvedInterestIsEnabled] =
    useState(false);
  const [devstarterInterestIsEnabled, setDevstarterInterestIsEnabled] =
    useState(false);

  const handleAcceptNewsletterUpdate = async () => {
    if (!isSaving) {
      setIsSaving(true);
      try {
        const restfulFunctions = getFunctions();
        const updateUserAcceptNewsletter = httpsCallable(
          restfulFunctions,
          "updateUserAcceptNewsletter"
        );
        await updateUserAcceptNewsletter({
          acceptNewsletter: !acceptNewsletter,
          email: userEmail,
        });
        setAcceptNewsletter(!acceptNewsletter);
        setIsSaving(false);
      } catch (err) {
        console.log(err);
        setIsSaving(false);
      }
    }
  };

  const handleInterestUpdate = async (
    interest: "DEV_REVIEW" | "DEVOLVED" | "DEV_STARTER"
  ) => {
    if (!isSaving) {
      setIsSaving(true);
      try {
        const restfulFunctions = getFunctions();
        const updateUserInterests = httpsCallable(
          restfulFunctions,
          "updateUserInterests"
        );
        const newInterestsState = {
          devReview:
            interest === "DEV_REVIEW"
              ? !devReviewIsEnabled
              : devReviewIsEnabled,
          devolved:
            interest === "DEVOLVED"
              ? !devolvedInterestIsEnabled
              : devolvedInterestIsEnabled,
          devstarter:
            interest === "DEV_STARTER"
              ? !devstarterInterestIsEnabled
              : devstarterInterestIsEnabled,
        };
        await updateUserInterests({
          wantsAPortfolioReview: newInterestsState.devReview,
          wantsDevolvedToExists: newInterestsState.devolved,
          wantsDevStarterToExists: newInterestsState.devstarter,
        });
        setDevReviewIsEnabled(newInterestsState.devReview);
        setDevolvedInterestIsEnabled(newInterestsState.devolved);
        setDevstarterInterestIsEnabled(newInterestsState.devstarter);
        setIsSaving(false);
      } catch (err) {
        console.log(err);
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    onAuthStateChanged($firebaseAuth, (user) => {
      if (user) {
        getDoc(doc($firestore, "user_privates", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setAcceptNewsletter(docSnap.data().acceptNewsletter || false);

            // Get current user email from auth
            setUserEmail(user.email || "");
          } else {
            setAcceptNewsletter(true);
            setUserEmail(user.email || "");
          }
        });

        getDoc(doc($firestore, "users", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUsername(docSnap.data().username);
            setFirstname(docSnap.data().firstname);
            setLastname(docSnap.data().lastname);
            setMainSkill(docSnap.data().mainSkill);
            setStartingYear(docSnap.data().startingYear);
            setStatus(docSnap.data().status);

            setSkillsIds(docSnap.data().skillsIds || []);
            setLinkedinId(docSnap.data().linkedinId);
            setCurrentPictureUrl(docSnap.data().profilePictureUrl);
            setSubSkillsIds(docSnap.data().subSkillsIds || []);
            setRewardsIds(docSnap.data().rewardsIds || []);
            setDescription(docSnap.data().description || "");
            setPortfolioUrl(docSnap.data().portfolioUrl || "");
            setGithubId(docSnap.data().githubId || "");
            setGeoId(docSnap.data().geoId || "");
            setContestantExperience(docSnap.data().contestantExperience || 0);
            setDevReviewIsEnabled(docSnap.data().wantsAPortfolioReview);
            setDevolvedInterestIsEnabled(docSnap.data().wantsDevolvedToExists);
            setDevstarterInterestIsEnabled(
              docSnap.data().wantsDevStarterToExists
            );
            setIsInit(true);
            if (
              docSnap.data().username &&
              docSnap.data().firstname &&
              docSnap.data().lastname &&
              docSnap.data().profilePictureUrl
            ) {
              setHasMinimalProfileCompletion(true);
            }
          } else {
            setHasMinimalProfileCompletion(true);
            console.log("NOT EXISTANT");
          }
        });
      } else {
        navigate("/");
      }
    });
  }, [navigate]);

  const skillSelectionHandler = (id: string) => {
    const alreadyIn = skillsIds.findIndex((skid) => skid === id);
    const alreadyInSub = subSkillsIds.findIndex((skid) => skid === id);

    if (alreadyIn !== -1) {
      const nSkills = [...skillsIds];
      nSkills.splice(alreadyIn, 1);
      return setSkillsIds(nSkills);
    }
    if (alreadyInSub !== -1) {
      const nSkills = [...subSkillsIds];
      nSkills.splice(alreadyInSub, 1);
      return setSubSkillsIds(nSkills);
    }
    if (skillsIds.length > 4) {
      setSubSkillsIds([...subSkillsIds, id]);
      return;
    }
    return setSkillsIds([...skillsIds, id]);
  };

  const updateProfileInfosHandler = async () => {
    setIsSaving(true);
    const year = new Date().getFullYear();
    if (startingYear > year) {
      setIsSaving(false);
      return uiCtx.setAlertPrompt({
        type: "ALERT",
        message: "L'année de démarrage n'est pas valide",
      });
    }
    const restfulFunctions = getFunctions();
    const updateUserProfileInfos = httpsCallable(
      restfulFunctions,
      "updateUserProfileInfos"
    );
    try {
      const response = (await updateUserProfileInfos({
        username,
        firstname,
        lastname,
        geoId,
        description,
        status,
        startingYear,
      })) as Record<string, any>;
      console.log(response);
      if (
        response.data.username &&
        response.data.firstname &&
        response.data.lastname &&
        response.data.profilePictureUrl
      ) {
        setHasMinimalProfileCompletion(true);
      }
      setIsSaving(false);
      uiCtx.setAlertPrompt({
        type: "Succès",
        message: "Mise à jour réussie",
      });
    } catch (err: any) {
      setIsSaving(false);
      console.log(err);
      uiCtx.setAlertPrompt({
        type: "Erreur",
        message:
          err.code === "functions/already-exists"
            ? "Ce pseudo n'est pas disponible !"
            : "Une erreur est survenue",
      });
    }
  };

  const updateSkillsHandler = () => {
    setIsSaving(true);

    const restfulFunctions = getFunctions();
    const updateUserSkills = httpsCallable(
      restfulFunctions,
      "updateUserSkills"
    );
    updateUserSkills({
      skillsIds,
      subSkillsIds,
    })
      .then(() => {
        setIsSaving(false);
        uiCtx.setAlertPrompt({
          type: "Succès",
          message: "Mise à jour réussie",
        });
      })
      .catch((err) => {
        setIsSaving(false);
        uiCtx.setAlertPrompt({
          type: "Erreur",
          message: "Une erreur est survenue",
        });
      });
  };

  const updateNetworksHandler = () => {
    setIsSaving(true);

    const restfulFunctions = getFunctions();
    const updateUserNetworks = httpsCallable(
      restfulFunctions,
      "updateUserNetworks"
    );
    updateUserNetworks({
      linkedinId,
      githubId,
      portfolioUrl,
    })
      .then(() => {
        setIsSaving(false);
        uiCtx.setAlertPrompt({
          type: "Succès",
          message: "Mise à jour réussie",
        });
      })
      .catch((err) => {
        setIsSaving(false);
        uiCtx.setAlertPrompt({
          type: "Erreur",
          message: "Une erreur est survenue",
        });
      });
  };

  const updateUserProfilePictureHandler = async () => {
    setIsSaving(true);

    const restfulFunctions = getFunctions();
    const updateUserProfilePicture = httpsCallable(
      restfulFunctions,
      "updateUserProfilePicture"
    );
    if (pendingProfilePictureFile.size && $firebaseAuth.currentUser) {
      const pp = (await resizeFile(pendingProfilePictureFile)) as File;
      const ppRef = ref(
        $firebaseStorage,
        `public/users/${$firebaseAuth.currentUser.uid}/pp.jpg`
      );
      uploadBytes(ppRef, pp)
        .then(async () => {
          const ppUrl = await getDownloadURL(ppRef);
          updateUserProfilePicture({
            profilePictureUrl: ppUrl,
            portfolioUrl,
            description,
            geoId,
          })
            .then((response: Record<string, any>) => {
              if (
                response.data.username &&
                response.data.firstname &&
                response.data.lastname &&
                response.data.profilePictureUrl
              ) {
                setHasMinimalProfileCompletion(true);
              }
              setIsSaving(false);
              setCurrentPictureUrl(ppUrl);
              uiCtx.setAlertPrompt({
                type: "Succès",
                message: "Mise à jour réussie",
              });
            })
            .catch((err) => {
              setIsSaving(false);
              uiCtx.setAlertPrompt({
                type: "Erreur",
                message: "Une erreur est survenue",
              });
            });
        })
        .catch((err) => {
          setIsSaving(false);
          uiCtx.setAlertPrompt({
            type: "Erreur",
            message: "Une erreur est survenue",
          });
        });
    }
  };

  const [parentRef] = useAutoAnimate<HTMLDivElement>();

  const askForDeletionConfirmation = () => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer votre compte ?"
    );
    if (confirmation) {
      setIsSaving(true);
      const restfulFunctions = getFunctions();
      const deleteUser = httpsCallable(restfulFunctions, "deleteAccount");
      deleteUser()
        .then(() => {
          setIsSaving(false);
          uiCtx.setAlertPrompt({
            type: "Succès",
            message: "Votre compte a été supprimé",
          });
          $firebaseAuth.signOut();
        })
        .catch((err) => {
          setIsSaving(false);
          uiCtx.setAlertPrompt({
            type: "Erreur",
            message: "Une erreur est survenue",
          });
        });
    }
  };

  return (
    <div className="profile-editor">
      <div className="start-column">
        <section className="profile-editor__member-card">
          {isInit === true ? (
            <MemberCard
              username={username}
              startingYear={startingYear}
              firstname={firstname}
              lastname={lastname}
              mainSkill={mainSkill}
              profilePictureUrl={currentPictureUrl}
              skills={skillsIds}
              status={status as "WORKER" | "STUDENT" | "OBSERVER"}
              linkedinId={linkedinId}
              subSkills={subSkillsIds}
              rewardsIds={rewardsIds}
              portfolioUrl={portfolioUrl}
              githubId={githubId}
              geoId={geoId}
              contestantExperience={contestantExperience}
            />
          ) : (
            <div className="skeleton" style={{ height: "13.5rem" }} />
          )}
        </section>
        {isInit === true && !hasMinimalProfileCompletion && (
          <section className="profile-editor__not-visible">
            <strong>Attention : </strong>Pour être visible sur la page d'accueil
            il faut renseigner au minimum un pseudo, un nom, un prénom et une
            photo de profil.
          </section>
        )}
        {/* <ContestPanel
          isInit={isInit}
          hasMinimalProfileCompletion={hasMinimalProfileCompletion}
        /> */}

        {
          // isInit === true && (
          // <section
          //   className="profile-editor__element profile-editor__interests"
          //   ref={parentRef}
          // >
          //   <h2
          //     style={{
          //       display: "flex",
          //       justifyContent: "space-between",
          //       alignItems: "center",
          //       opacity: isInit ? 1 : 0,
          //       marginBottom: 0,
          //     }}
          //     onClick={() => setInterestsPanelIsOpen(!interestsPanelIsOpen)}
          //   >
          //     <span>Projets du Club 🚀</span>
          //     <BaseIcon
          //       icon={interestsPanelIsOpen ? faChevronUp : faChevronDown}
          //       size="1rem"
          //     />
          //   </h2>
          //   {interestsPanelIsOpen && (
          //     <div className="profile-editor__interests__container">
          //       <div className="profile-editor__interests__container__item">
          //         <div className="profile-editor__interests__container__item__description">
          //           <h3
          //             style={{
          //               fontSize: "20px",
          //               color: "var(--secondary-color)",
          //               fontWeight: "800",
          //             }}
          //           >
          //             Dev Review 📗
          //           </h3>
          //           <p>
          //             En activant ce bouton tu donnes ton accord pour que ton
          //             portfolio soit étudié dans un futur épisode de Dev Review.
          //           </p>
          //         </div>
          //         <div className="profile-editor__interests__container__item__description__action">
          //           <BaseButton
          //             name={devReviewIsEnabled ? "Activé 👍" : "Pas activé"}
          //             onClick={() => handleInterestUpdate("DEV_REVIEW")}
          //             color={
          //               devReviewIsEnabled ? "var(--secondary-color)" : "gray"
          //             }
          //             isLoading={isSaving}
          //           />
          //         </div>
          //       </div>
          //       <div className="profile-editor__interests__container__item">
          //         <div className="profile-editor__interests__container__item__description">
          //           <h3>
          //             <img
          //               style={{ height: "14px" }}
          //               src={logoDevolved}
          //               alt="logo devolved"
          //             />
          //           </h3>
          //           <p>
          //             En activant ce bouton tu montres ton intérêt pour le
          //             projet Devolved. Le jour où l'application sera disponible
          //             une surprise t'attendra.
          //           </p>
          //         </div>
          //         <div className="profile-editor__interests__container__item__description__action">
          //           <BaseButton
          //             name={
          //               devolvedInterestIsEnabled ? "Activé 👍" : "Pas activé"
          //             }
          //             onClick={() => handleInterestUpdate("DEVOLVED")}
          //             color={
          //               devolvedInterestIsEnabled
          //                 ? "var(--secondary-color)"
          //                 : "gray"
          //             }
          //             isLoading={isSaving}
          //           />
          //         </div>
          //       </div>
          //       <div className="profile-editor__interests__container__item">
          //         <div className="profile-editor__interests__container__item__description">
          //           <h3>
          //             <img
          //               style={{ height: "24px" }}
          //               src={logoDevStarter}
          //               alt="logo devstarter"
          //             />
          //           </h3>
          //           <p>
          //             En activant ce bouton tu montres ton intérêt pour la
          //             création d'une formation premium pour démarrer dans le
          //             développement web.
          //           </p>
          //         </div>
          //         <div className="profile-editor__interests__container__item__description__action">
          //           <BaseButton
          //             name={
          //               devstarterInterestIsEnabled ? "Activé 👍" : "Pas activé"
          //             }
          //             onClick={() => handleInterestUpdate("DEV_STARTER")}
          //             color={
          //               devstarterInterestIsEnabled
          //                 ? "var(--secondary-color)"
          //                 : "gray"
          //             }
          //             isLoading={isSaving}
          //           />
          //         </div>
          //       </div>
          //     </div>
          //   )}
          // </section>
          //)
        }

        {isInit === true ? (
          <section className="profile-editor__element profile-editor__infos">
            <h2>Profil public</h2>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                updateProfileInfosHandler();
              }}
            >
              <BaseInput
                value={username}
                onValueChange={(val: string) => setUsername(val)}
                label="Pseudo (max. 15 caractères)"
                maxLength={15}
                noMargin
                margin="0 0 1rem 0"
              />
              <BaseInput
                value={firstname}
                onValueChange={(val: string) => setFirstname(val)}
                label="Prénom (réel ou fictif)"
                noMargin
                margin="0 0 1rem 0"
              />
              <BaseInput
                value={lastname}
                onValueChange={(val: string) => setLastname(val)}
                label="Nom (réel ou fictif)"
                noMargin
                margin="0 0 1rem 0"
              />
              <div className="geo-selector">
                <label>Localisation :</label>
                <select
                  name="geo"
                  id="geo-select"
                  onChange={(event) => setGeoId(event.target.value)}
                  value={geoId}
                >
                  <option value="">-</option>
                  {geoConfig.map((geoItem) => (
                    <option key={"select-" + geoItem.id} value={geoItem.id}>
                      {geoItem.name}
                    </option>
                  ))}
                </select>
              </div>
              <BaseTextArea
                label="Description"
                value={description}
                onValueChange={(val: string) => setDescription(val)}
              />
              <label>Mon statut actuel :</label>
              <div className="status-selector">
                <div
                  onClick={() => setStatus("WORKER")}
                  className={classNames({
                    "status-selector__item": true,
                    "status-selector__item--active": status === "WORKER",
                  })}
                >
                  Diplômé
                </div>
                <div
                  onClick={() => setStatus("STUDENT")}
                  className={classNames({
                    "status-selector__item": true,
                    "status-selector__item--active": status === "STUDENT",
                  })}
                >
                  Étudiant
                </div>
                <div
                  onClick={() => setStatus("OBSERVER")}
                  className={classNames({
                    "status-selector__item": true,
                    "status-selector__item--active": status === "OBSERVER",
                  })}
                >
                  Observateur
                </div>
              </div>
              <BaseInput
                type="number"
                value={startingYear.toString()}
                onValueChange={(val: string) => setStartingYear(+val)}
                label="Année de démarrage"
                disabled={status === "OBSERVER"}
              />
              <div className="profile-editor__element__control">
                <BaseButton
                  extended
                  onClick={() => {}}
                  name="Mettre à jour"
                  icon={faSave}
                  color={"var(--secondary-color)"}
                  type="submit"
                  isLoading={isSaving}
                  disabled={!username}
                />
              </div>
            </form>
          </section>
        ) : (
          <div className="skeleton" style={{ height: "746px" }} />
        )}
        {isInit === true && (
          <section className="profile-editor__element profile-editor__newsletter">
            <div
              className="account-newsletter-field"
              style={{
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "all",
              }}
            >
              <BaseToggle
                value={acceptNewsletter}
                onClick={() => handleAcceptNewsletterUpdate()}
              />
              <label>
                J'accepte de recevoir la newsletter du DevClub{" "}
                <span>(infos, projets, formations, etc.)</span>
              </label>
            </div>
          </section>
        )}
        {isInit === true ? (
          <section className="profile-editor__element">
            <BaseButton
              extended
              onClick={() => askForDeletionConfirmation()}
              name="Supprimer mon compte"
              color="var(--danger-color)"
            />
          </section>
        ) : (
          <div className="skeleton" style={{ height: "68px" }} />
        )}
      </div>
      <div className="end-column">
        {isInit === true ? (
          <section className="profile-editor__element profile-editor__picture">
            <h2>Photo de profil</h2>
            <BaseImageInput
              // presetValue={URL.createObjectURL(pendingProfilePictureFile)}
              value={pendingProfilePictureFile}
              onImageSelected={(file: File) =>
                setPendingProfilePictureFile(file)
              }
            />
            <div className="profile-editor__element__control">
              <BaseButton
                extended
                onClick={() => updateUserProfilePictureHandler()}
                name="Mettre à jour"
                icon={faSave}
                color={"var(--secondary-color)"}
                isLoading={isSaving}
                disabled={!pendingProfilePictureFile.size}
              />
            </div>
          </section>
        ) : (
          <div className="skeleton" style={{ height: "233.5px" }} />
        )}
        {isInit === true ? (
          <section className="profile-editor__element profile-editor__skills">
            <h2>Compétences</h2>
            <label className="label-with-button">
              <span>Mes skills </span>{" "}
              <span
                className="reset-skills-button"
                onClick={() => {
                  setSkillsIds([]);
                  setSubSkillsIds([]);
                }}
              >
                <BaseIcon icon={faClockRotateLeft} size="0.825rem" />
              </span>
            </label>
            {[
              { miniLabel: "Langages", type: "LANGUAGE" },
              { miniLabel: "Frontend", type: "FRONTEND" },
              { miniLabel: "Backend", type: "BACKEND" },
              { miniLabel: "Outils/Autres", type: "TOOLS" },
            ].map((grid, index) => (
              <div key={grid.type}>
                <label className="mini-label">{grid.miniLabel} :</label>
                <div
                  className={classNames({
                    "skills-grid": true,
                    "skills-grid--last": index === 3,
                  })}
                >
                  {Object.keys(skills)
                    .filter((skillKey) => skills[skillKey].type === grid.type)
                    .map((skillKey: string) => (
                      <div
                        key={skills[skillKey].id}
                        onClick={() =>
                          skillSelectionHandler(skills[skillKey].id)
                        }
                      >
                        <SkillIcon
                          skill={skills[skillKey]}
                          selected={
                            !!skillsIds.find(
                              (skid) => skid === skills[skillKey].id
                            ) ||
                            !!subSkillsIds.find(
                              (skid) => skid === skills[skillKey].id
                            )
                          }
                          isMain={
                            !!skillsIds.find(
                              (skid) => skid === skills[skillKey].id
                            )
                          }
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
            <BaseButton
              extended
              onClick={() => updateSkillsHandler()}
              name="Mettre à jour"
              icon={faSave}
              color={"var(--secondary-color)"}
              isLoading={isSaving}
              disabled={skillsIds.length === 0}
            />
          </section>
        ) : (
          <div className="skeleton" style={{ height: "670.5px" }} />
        )}
        {isInit === true ? (
          <section className="profile-editor__element profile-editor__networks">
            <h2>Réseaux</h2>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                updateNetworksHandler();
              }}
            >
              <BaseInput
                value={githubId}
                onValueChange={(val: string) => setGithubId(val)}
                label="ID Github"
                noMargin
                margin="0 0 1rem 0"
              />
              <BaseInput
                value={linkedinId}
                onValueChange={(val: string) => setLinkedinId(val)}
                label="ID Linkedin"
                noMargin
                margin="0 0 1rem 0"
              />
              <BaseInput
                value={portfolioUrl}
                onValueChange={(val: string) => setPortfolioUrl(val)}
                label="URL de mon portfolio"
              />
              <BaseButton
                type="submit"
                extended
                onClick={() => {}}
                name="Mettre à jour"
                icon={faSave}
                color={"var(--secondary-color)"}
                isLoading={isSaving}
                disabled={!githubId && !linkedinId && !portfolioUrl}
              />
            </form>
          </section>
        ) : (
          <div className="skeleton" style={{ height: "364.5px" }} />
        )}
        {isInit === true ? (
          <section className="profile-editor__element">
            <BaseButton
              extended
              onClick={async () => {
                await signOut($firebaseAuth);
                navigate("/");
              }}
              name="Se déconnecter"
              color="orange"
            />
          </section>
        ) : (
          <div className="skeleton" style={{ height: "68px" }} />
        )}
      </div>
    </div>
  );
};

export default ProfileEditor;
