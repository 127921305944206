import { faStar } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useContext } from "react";
import UIContext from "../../contexts/ui.ctx";
import BaseIcon from "../Base/BaseIcon/BaseIcon";
import "./SkillIcon.css";

interface SkillIconProps {
  skill: {
    name: string;
    icon: string;
  };
  mini?: boolean;
  selected?: boolean;
  isMain?: boolean;
  maxi?: boolean;
  inList?: boolean;
}

const SkillIcon: React.FC<SkillIconProps> = (props) => {
  const uiCtx = useContext(UIContext);
  return (
    <div
      onMouseEnter={() =>
        uiCtx.setMouseInfo(props.maxi ? "" : props.skill.name)
      }
      onMouseLeave={() => uiCtx.setMouseInfo("")}
      onTouchStart={() => uiCtx.setMouseInfo("")}
      onTouchEnd={() => uiCtx.setMouseInfo("")}
      className={classNames({
        "skill-icon": true,
        "skill-icon--mini": props.mini,
        "skill-icon--maxi": props.maxi,
        "skill-icon--selected": props.selected,
        "skill-icon--selected-main": props.selected && props.isMain,
        "skill-icon--inList": props.inList,
      })}
      style={{
        backgroundImage: `url("${props.skill.icon}")`,
      }}
    >
      {props.isMain && (
        <span className="skill-icon__star">
          <BaseIcon icon={faStar} color="gold" size="10px" />
        </span>
      )}
    </div>
  );
};

export default SkillIcon;
