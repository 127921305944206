import React from "react";
import { useState } from "react";

const UIContext = React.createContext({
  mouseInfo: "",
  setMouseInfo: (value: string) => {},
  alertPrompt: {
    type: "",
    message: "",
  } as AlertPromptData,
  setAlertPrompt: (data: AlertPromptData) => {},
  rewardPrompt: {
    type: "WIN",
    gain: 0,
  },
  setRewardPrompt: (data: RewardPromptData) => {},
  returnToFirstPageTrigger: false,
  setReturnToFirstPageTrigger: () => {},
});

type AlertPromptData = {
  type: string;
  message: string;
};

type RewardPromptData = {
  type: "WIN" | "LOOSE";
  gain: number;
};

export const UIContextProvider: React.FC = (props) => {
  const [mouseInfo, setMouseInfo] = useState("");
  const [alertPrompt, setAlertPrompt] = useState({
    type: "",
    message: "",
  } as AlertPromptData);
  const [rewardPrompt, setRewardPrompt] = useState({
    type: "WIN",
    gain: 0,
  } as RewardPromptData);

  const [returnToFirstPageTrigger, setReturnToFirstPageTrigger] =
    useState(false);

  return (
    <UIContext.Provider
      value={{
        mouseInfo,
        setMouseInfo: (value: string) => setMouseInfo(value),
        alertPrompt,
        setAlertPrompt: (data: AlertPromptData) => setAlertPrompt(data),
        rewardPrompt,
        setRewardPrompt: (data: RewardPromptData) => setRewardPrompt(data),
        returnToFirstPageTrigger,
        setReturnToFirstPageTrigger: () =>
          setReturnToFirstPageTrigger(!returnToFirstPageTrigger),
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export default UIContext;
