import { faUser } from "@fortawesome/free-solid-svg-icons";
import BaseIcon from "../Base/BaseIcon/BaseIcon";

import "./PPPlaceholder.css";

interface PPPlaceholderProps {}

const PPPlaceholder: React.FC<PPPlaceholderProps> = (props) => {
  return (
    <div className="pp-placeholder">
      <span>
        <BaseIcon icon={faUser} size="4.5rem" color="var(--grey-color)" />
      </span>
    </div>
  );
};

export default PPPlaceholder;
