import { sendPasswordResetEmail } from "firebase/auth";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UIContext from "../../contexts/ui.ctx";
import { $firebaseAuth } from "../../services/app/firebase-service";
import BaseButton from "../Base/BaseButton/BaseButton";
import BaseInput from "../Base/BaseInput/BaseInput";

interface PWForgetFormProps {}

const PWForgetForm: React.FC<PWForgetFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const uiCtx = useContext(UIContext);
  const location = useLocation();

  const askPWReset = async () => {
    if (!email) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Veuillez entrer un email valide.",
      });
    }
    setIsLoading(true);
    sendPasswordResetEmail($firebaseAuth, email)
      .then((response: any) => {
        setIsLoading(false);
        navigate("/signin");
        console.log(response);
        uiCtx.setAlertPrompt({
          type: "Succès",
          message:
            "Un email de récupération a été envoyé à l'adresse email renseignée",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        uiCtx.setAlertPrompt({
          type: "Erreur",
          message: "Une erreur inconnue est survenue...",
        });
      });
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        askPWReset();
      }}
    >
      <div className="form-card__content__form">
        <BaseInput
          onValueChange={(val: string) => setEmail(val)}
          value={email}
          label="Email de récupération"
          type="email"
        />
      </div>
      <div className="form-card__content__control">
        <BaseButton
          name="Envoyer l'email"
          extended
          isLoading={isLoading}
          type="submit"
          onClick={() => {}}
        />
        <Link
          to={"/signin"}
          state={{ prevPath: location.pathname }}
          className="form-card__content__control__signin"
        >
          Annuler
        </Link>
      </div>
    </form>
  );
};

export default PWForgetForm;
