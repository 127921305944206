import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UIContext from "../../contexts/ui.ctx";
import { $firebaseAuth } from "../../services/app/firebase-service";
import BaseButton from "../Base/BaseButton/BaseButton";
import BaseInput from "../Base/BaseInput/BaseInput";
import "./SignInForm.css";

interface SignInFormProps {}

const SignInForm: React.FC<SignInFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const uiCtx = useContext(UIContext);
  const location = useLocation();

  useEffect(() => {
    onAuthStateChanged($firebaseAuth, (user) => {
      if (user) {
        navigate("/my-account");
      }
    });
  }, [navigate]);

  const signIn = async () => {
    if (!email) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Veuillez entrer un email valide.",
      });
    }
    if (!password) {
      return uiCtx.setAlertPrompt({
        type: "Attention !",
        message: "Veuillez entrer un mot de passe d'au moins 6 caractères.",
      });
    }
    setIsLoading(true);
    setPersistence($firebaseAuth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword($firebaseAuth, email, password)
          .then((signInResponse: any) => {})
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
            uiCtx.setAlertPrompt({
              type: "Erreur",
              message: "Une erreur est survenue",
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        uiCtx.setAlertPrompt({
          type: "Erreur",
          message: "Une erreur est survenue",
        });
      });
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        signIn();
      }}
    >
      <div className="form-card__content__form">
        <BaseInput
          onValueChange={(val: string) => setEmail(val)}
          value={email}
          label="Email"
          type="email"
        />
        <BaseInput
          onValueChange={(val: string) => setPassword(val)}
          value={password}
          label="Mot de passe"
          type="password"
          noMargin
        />
        <Link
          to={"/pw-forget"}
          state={{ prevPath: location.pathname }}
          className="form-card__content__form__pw-forget"
        >
          Mot de passe oublié ?
        </Link>
      </div>
      <div className="form-card__content__control">
        <BaseButton
          name="Se connecter"
          extended
          isLoading={isLoading}
          type="submit"
          onClick={() => {}}
        />
        <Link
          to={"/signup"}
          state={{ prevPath: location.pathname }}
          className="form-card__content__control__signin"
        >
          S'inscrire
        </Link>
      </div>
    </form>
  );
};

export default SignInForm;
