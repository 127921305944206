import {
  faCheck,
  faClose,
  faCommentDots,
  faExclamation,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import BaseIcon from "../Base/BaseIcon/BaseIcon";
import "./ConfirmPrompt.css";

interface ConfirmPromptProps {
  onClose: Function;
}
const ConfirmPrompt: React.FC<ConfirmPromptProps> = (props) => {
  const PROMPT_HEIGHT = 327;

  return ReactDOM.createPortal(
    <>
      <div className="confirm-curtain" />
      <div
        className={classNames({
          "confirm-prompt": true,
        })}
        style={{
          top: `calc(50vh - ${PROMPT_HEIGHT / 2}px)`,
        }}
      >
        <div className="confirm-prompt__title">Réservation confirmée !</div>
        <BaseIcon icon={faCheck} size="5rem" color="lime" />
        <div className="confirm-prompt__info-content">
          <div style={{ lineHeight: "24px" }}>
            Vous allez recevoir un mail contenant un lien pour rejoindre la
            session de mentorat à la date prévue. Si il y a un problème,
            n'hésitez pas à me contacter à l'adresse{" "}
            <strong>mentorat.devclub@gmail.com</strong>.
          </div>
        </div>{" "}
        <div className="confirm-prompt__controls">
          <button onClick={() => props.onClose()}>Fermer</button>
        </div>
      </div>
    </>,
    document.body
  );
};

export default ConfirmPrompt;
