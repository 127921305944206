import "./LoginLayout.css";

interface LoginLayoutProps {
  formTitle: string;
}

const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
  return (
    <div className="login-layout">
      <section className="form-section">
        <div className="form-card">
          <header className="form-card__header">{props.formTitle}</header>
          <div className="form-card__content">{props.children}</div>
        </div>
      </section>
    </div>
  );
};

export default LoginLayout;
