import buildEnvConfig from "../build.env.json";

const rcv3Config = () => {
  if (buildEnvConfig.BUILD_ENV === "PROD") {
    return { pk: "6LdrhhkgAAAAAKiFYpX7L6VJWENSfIBkihKrIRui" };
  } else {
    return { pk: "6LezwxkgAAAAAOX0sU2qp3OFY3oSgD4_a63rFM4y" };
  }
};
export default rcv3Config;
