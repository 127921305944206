import classNames from "classnames";
import React from "react";

import "./BaseInput.css";

interface ContainerProps {
  label?: string;
  value: string;
  onValueChange: Function;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  noSpace?: boolean;
  noMargin?: boolean;
  margin?: string;
}

const BaseInput: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className={classNames({
        "base-input": true,
        "base-input--no-margin": props.noMargin,
      })}
      data-testid="base-input"
      style={{
        margin: props.margin,
      }}
    >
      {props.label && (
        <label
          style={{
            opacity: props.disabled ? 0.5 : 1,
          }}
        >
          {props.label + " :"}
        </label>
      )}
      <input
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type || undefined}
        value={props.disabled ? "" : props.value}
        onChange={(event) =>
          props.onValueChange(
            props.noSpace ? event.target.value.trim() : event.target.value
          )
        }
        maxLength={props.maxLength}
      />
    </div>
  );
};

export default BaseInput;
