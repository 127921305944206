import PWForgetForm from "../components/Forms/PWForgetForm";
import LoginLayout from "../components/Layout/LoginLayout";

const PWForget: React.FC = () => {
  return (
    <LoginLayout formTitle="Récupération mot de passe">
      <PWForgetForm />
    </LoginLayout>
  );
};
export default PWForget;
