import ProfileEditor from "../components/Core/ProfileEditor";

const MyAccount: React.FC = () => {
  return (
    <>
      <ProfileEditor />
    </>
  );
};
export default MyAccount;
