import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faBookAtlas,
  faEye,
  faGraduationCap,
  faLaptopCode,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SkillIcon from "../components/Core/SkillIcon";
import skillsConfig from "../configs/skills.config";
import $firestore from "../services/app/firebase-service";

import "./ProfileDetail.css";
import BaseIcon from "../components/Base/BaseIcon/BaseIcon";
import PPPlaceholder from "../components/Core/PPPlaceholder";
import dcLogo from "../assets/logo/colored-solo.svg";

interface ProfileDetailProps {}

type MemberType = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  mainSkill: string;
  skills: string[];
  subSkills: string[];
  status: string;
  startingYear: number;
  profilePictureUrl: string;
  linkedinId: string;
  description: string;
  portfolioUrl: string;
  rewards: string[];
  createdAt: any;
  geoId: string;
  githubId: string;
  contestantExperience: number;
};

const ProfileDetail: React.FC<ProfileDetailProps> = (props) => {
  const [user, setUser] = useState({} as MemberType);
  const [noUserFound, setNoUserFound] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    if (!user.id) {
      getDocs(
        query(collection($firestore, "users"), where("username", "==", id))
      )
        .then((docs) => {
          const list = [] as MemberType[];
          docs.forEach((doc) =>
            list.push({
              id: doc.id,
              username: doc.data().username,
              firstname: doc.data().firstname,
              lastname: doc.data().lastname,
              mainSkill: doc.data().mainSkill,
              skills: doc.data().skillsIds || [],
              subSkills: doc.data().subSkillsIds || [],
              status: doc.data().status,
              startingYear: doc.data().startingYear,
              profilePictureUrl: doc.data().profilePictureUrl,
              linkedinId: doc.data().linkedinId,
              description: doc.data().description || "-",
              portfolioUrl: doc.data().portfolioUrl,
              rewards: doc.data().rewardsIds || [],
              createdAt: doc.data().createdAt,
              geoId: doc.data().geoId || "",
              githubId: doc.data().githubId || "",
              contestantExperience: doc.data().contestantExperience || 0,
            })
          );
          if (list[0]) {
            return setUser(list[0]);
          }
          setNoUserFound(true);
        })
        .catch((err) => {
          console.log(err);
          alert("Une erreur inconnue est survenue");
        });
    }
  }, [user, id]);

  const getStartingYear = () => {
    const now = new Date().getFullYear();
    return now - user.startingYear;
  };

  const getComputedSkills = (
    type: "FRONTEND" | "BACKEND" | "LANGUAGE" | "TOOLS"
  ) => {
    if (user.skills) {
      return [...user.skills, ...user.subSkills].filter(
        (skillName) => skillsConfig[skillName].type === type
      );
    }
  };

  return (
    <div className="profile-detail-page">
      {!noUserFound && user.id && (
        <>
          <section
            style={{ gridArea: "identity" }}
            className="profile-detail-page__member-card"
          >
            {user.profilePictureUrl ? (
              <img src={user.profilePictureUrl} alt="user-profile" />
            ) : (
              <PPPlaceholder />
            )}

            <div className="profile-detail-page__member-card__socials">
              {user.githubId && (
                <div
                  className="profile-detail-page__member-card__socials__display"
                  onClick={() => {
                    window.open("https://github.com/" + user.githubId);
                  }}
                >
                  <span>
                    <BaseIcon icon={faGithub} size="1.25rem" />
                  </span>
                </div>
              )}
              {user.linkedinId && (
                <div
                  className="profile-detail-page__member-card__socials__display"
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/in/" + user.linkedinId
                    );
                  }}
                >
                  <span>
                    <BaseIcon icon={faLinkedin} size="1.25rem" />
                  </span>
                </div>
              )}
              {user.portfolioUrl && (
                <div
                  className="profile-detail-page__member-card__socials__display"
                  onClick={() => {
                    const result = window.confirm(
                      `⚠️ Attention ! Les portfolios des membres sont des URLs non vérifiées, souhaitez-vous continuer ? (${user.portfolioUrl})`
                    );
                    if (result) {
                      window.open(user.portfolioUrl);
                    }
                  }}
                >
                  <span>
                    <BaseIcon icon={faBookAtlas} size="1.25rem" />
                  </span>
                </div>
              )}
            </div>
            <div className="profile-detail-page__member-card__identity">
              <span>{user.firstname || "-"}</span>
              <span>{user.lastname || "-"}</span>
              <span>{user.username ? `#${user.username}` : "-"}</span>
            </div>
            <div className="profile-detail-page__member-card__divider" />
            <div className="profile-detail-page__member-card__sub-infos">
              <span>
                <BaseIcon
                  icon={faLocationDot}
                  size={"0.825rem"}
                  color={"var(--primary-color)"}
                />{" "}
                {user.geoId !== "00" && user.geoId
                  ? "France - " + user.geoId
                  : "-"}
              </span>
              <span>
                {
                  <BaseIcon
                    icon={
                      user.status === "WORKER"
                        ? faLaptopCode
                        : user.status === "OBSERVER"
                        ? faEye
                        : faGraduationCap
                    }
                    size={"0.825rem"}
                    color={"var(--primary-color)"}
                  />
                }
                {user.status === "WORKER"
                  ? getStartingYear().toString() + " ans d'XP"
                  : user.status === "OBSERVER"
                  ? "Observe"
                  : "Se forme"}
              </span>
              <span>
                <img
                  className="dc-logo-scoring"
                  src={dcLogo}
                  alt="dc-logo-score"
                />
                {user.contestantExperience === 987654321
                  ? "😎"
                  : user.contestantExperience}
              </span>
            </div>
          </section>
          <section
            style={{ gridArea: "about" }}
            className="profile-detail-page__card"
          >
            <h2>A propos</h2>
            <h3>Description :</h3>
            <p>{user.description}</p>
          </section>
          <section
            style={{ gridArea: "skills" }}
            className="profile-detail-page__card"
          >
            <h2>Compétences</h2>
            {getComputedSkills("LANGUAGE")?.length !== 0 && (
              <>
                <h3>Langages :</h3>
                <div className="profile-detail-page__card__skills">
                  {getComputedSkills("LANGUAGE")?.map((skill) => (
                    <div
                      className="profile-detail-page__card__skill-item"
                      key={skill}
                    >
                      <SkillIcon skill={skillsConfig[skill]} inList />
                      {skillsConfig[skill].name}
                    </div>
                  ))}
                </div>
              </>
            )}
            {getComputedSkills("FRONTEND")?.length !== 0 && (
              <>
                <h3>Frontend :</h3>
                <div className="profile-detail-page__card__skills">
                  {getComputedSkills("FRONTEND")?.map((skill) => (
                    <div
                      className="profile-detail-page__card__skill-item"
                      key={skill}
                    >
                      <SkillIcon skill={skillsConfig[skill]} inList />
                      {skillsConfig[skill].name}
                    </div>
                  ))}
                </div>
              </>
            )}
            {getComputedSkills("BACKEND")?.length !== 0 && (
              <>
                <h3>Backend :</h3>
                <div className="profile-detail-page__card__skills">
                  {getComputedSkills("BACKEND")?.map((skill) => (
                    <div
                      className="profile-detail-page__card__skill-item"
                      key={skill}
                    >
                      <SkillIcon skill={skillsConfig[skill]} inList />
                      {skillsConfig[skill].name}
                    </div>
                  ))}
                </div>
              </>
            )}
            {getComputedSkills("TOOLS")?.length !== 0 && (
              <>
                <h3>Outils/Autres :</h3>
                <div className="profile-detail-page__card__skills">
                  {getComputedSkills("TOOLS")?.map((skill) => (
                    <div
                      className="profile-detail-page__card__skill-item"
                      key={skill}
                    >
                      <SkillIcon skill={skillsConfig[skill]} inList />
                      {skillsConfig[skill].name}
                    </div>
                  ))}
                </div>
              </>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default ProfileDetail;
