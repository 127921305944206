import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
// import logo from "../../assets/logo/logo.png";

import logo from "../../assets/logo/colored-typo.svg";
import { useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { $firebaseAuth } from "../../services/app/firebase-service";
import classNames from "classnames";
import UIContext from "../../contexts/ui.ctx";
interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const location = useLocation();

  useEffect(() => {
    onAuthStateChanged($firebaseAuth, (user) => {
      if (user && isAuth === false) {
        setIsAuth(true);
        setIsInit(true);
      } else if (!user && isAuth === true) {
        setIsAuth(false);
        setIsInit(true);
      }
    });
    setIsInit(true);
  }, [isAuth]);

  const uiCTX = useContext(UIContext);

  return (
    <div
      className={classNames({
        navbar: true,
        "navbar--dark": location.pathname === "/mentorat",
      })}
    >
      <div className="navbar-content">
        <Link
          to={"/"}
          state={{ prevPath: location.pathname }}
          className="navbar-content__logo"
          onClick={() => uiCTX.setReturnToFirstPageTrigger()}
        >
          <img src={logo} alt="nav-logo" />
        </Link>
        <div
          className="navbar-content__actions"
          style={{
            opacity: isInit ? 1 : 0,
            transition: "opacity 200ms ease 200ms",
          }}
        >
          <Link
            to={"/"}
            state={{ prevPath: location.pathname }}
            className={classNames({
              "navbar-content__actions__members": true,
              "action--active": location.pathname === "/",
            })}
            onClick={() => uiCTX.setReturnToFirstPageTrigger()}
          >
            Membres
          </Link>
          {isAuth ? (
            <Link
              state={{ prevPath: location.pathname }}
              to={"/my-account"}
              className={classNames({
                "navbar-content__actions__join": true,
                "action--active": location.pathname === "/my-account",
              })}
            >
              Mon Profil
            </Link>
          ) : (
            <Link
              to={"/signup"}
              state={{ prevPath: location.pathname }}
              className="navbar-content__actions__join"
            >
              Rejoindre
            </Link>
          )}
        </div>
      </div>
      <div className="navbar-decoration"></div>
    </div>
  );
};

export default NavBar;
