import { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MouseInfo from "./components/UI/MouseInfo";
import rcv3Config from "./configs/rcv3.config";
import UIContext from "./contexts/ui.ctx";
import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import ProfileDetail from "./pages/ProfileDetail";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AlertPrompt from "./components/UI/AlertPrompt";
import NavBar from "./components/Core/NavBar";
import PWForget from "./pages/PWForget";
import env from "./build.env.json";
import Suspension from "./components/Core/Suspension";
import Engage from "./pages/Engage";
import Mentorat from "./pages/Mentorat";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAnalytics } from "./hooks/useAnalytics";
import MaterialsShowcase from "./pages/MaterialsShowcase";
// Used to allow rcv3 to work with TS
declare global {
  interface Window {
    grecaptcha: any;
  }
}

const SUSPENDED = false;

const stripe = loadStripe(
  env.BUILD_ENV === "PROD"
    ? "pk_live_51Mvia7C6wrRutGxjP3tUoiEhRJuB7xPVcEZdihhSf2KvGxGupHNSY6qMCXttWlbYmXc9GrDv8pvNy4Hym4Fyk91E00XiDM7uCV"
    : "pk_test_51Mvia7C6wrRutGxj03GitMqO1QOsp7AaZ1xcSkmesUmhMWUNNsNzLZfstHBtdAZy8HGGVsngc3dlrJAIjolUCP0i00jYHOxHEf"
);

function App() {
  const uiCtx = useContext(UIContext);
  useAnalytics();

  useEffect(() => {
    const loadScriptByURL = (id: any, url: any, callback: any) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${rcv3Config().pk}`,
      function () {}
    );
  }, []);

  return env.BUILD_ENV === "PROD" && SUSPENDED ? (
    <>
      <Suspension />
    </>
  ) : (
    <>
      {uiCtx.mouseInfo && <MouseInfo />}
      <AlertPrompt />
      <NavBar />
      <div className="page-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/pw-forget" element={<PWForget />} />
          <Route path="/engage" element={<Engage />} />
          {/* <Route path="/mat" element={<MaterialsShowcase />} /> */}
          <Route
            path="/mentorat"
            element={
              <Elements stripe={stripe}>
                <Mentorat />
              </Elements>
            }
          />
          <Route path="/u/:id" element={<ProfileDetail />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
