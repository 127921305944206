import classNames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import UIContext from "../../contexts/ui.ctx";
import "./AlertPrompt.css";

interface AlertPromptProps {}

const AlertPrompt: React.FC<AlertPromptProps> = (props) => {
  const [height, setHeight] = useState(0);
  const [alertIsActive, setAlertIsActive] = useState(false);

  const uiCtx = useContext(UIContext);

  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (element?.current?.clientHeight) {
      setHeight(element?.current?.clientHeight);
    }
  }, []);
  useEffect(() => {
    if (uiCtx.alertPrompt.message) {
      const alertDelay = setTimeout(() => {
        setAlertIsActive(true);
      }, 16);
      return () => clearTimeout(alertDelay);
    } else {
      setAlertIsActive(false);
    }
  }, [uiCtx.alertPrompt.message]);

  return (
    <>
      {alertIsActive && <div className="alert-curtain" />}
      <div
        className={classNames({
          "alert-prompt": true,
          "alert-prompt--active": alertIsActive,
        })}
        style={{
          top: `calc(50vh - ${height / 2}px)`,
        }}
        ref={element}
      >
        <div className="alert-prompt__title">{uiCtx.alertPrompt.type}</div>
        <div className="alert-prompt__info-content">
          {uiCtx.alertPrompt.message}
        </div>
        <div className="alert-prompt__controls">
          <button
            onClick={() => uiCtx.setAlertPrompt({ type: "", message: "" })}
          >
            Fermer
          </button>
        </div>
      </div>
    </>
  );
};

export default AlertPrompt;
