import SignUpForm from "../components/Forms/SignUpForm";
import LoginLayout from "../components/Layout/LoginLayout";

const SignUp: React.FC = () => {
  return (
    <LoginLayout formTitle="S'inscrire">
      <SignUpForm />
    </LoginLayout>
  );
};
export default SignUp;
