import classNames from "classnames";
import React from "react";
import "./BaseLoadingCircle.css";

interface ContainerProps {
  color?: string;
  mini?: boolean;
}

const BaseLoadingCircle: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className={classNames({ "lds-ring": true, "lds-ring--mini": props.mini })}
    >
      <div
        className="lds-ring__circle"
        style={{
          borderColor: props.color
            ? props.color + " transparent transparent transparent"
            : undefined,
        }}
      ></div>
      <div
        className="lds-ring__circle"
        style={{
          borderColor: props.color
            ? props.color + " transparent transparent transparent"
            : undefined,
        }}
      ></div>
      <div
        className="lds-ring__circle"
        style={{
          borderColor: props.color
            ? props.color + " transparent transparent transparent"
            : undefined,
        }}
      ></div>
      <div
        className="lds-ring__circle"
        style={{
          borderColor: props.color
            ? props.color + " transparent transparent transparent"
            : undefined,
        }}
      ></div>
    </div>
  );
};

export default BaseLoadingCircle;
