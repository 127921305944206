// Adapted from https://world.hey.com/david.harting/using-the-mouse-position-with-react-hooks-and-rxjs-94883bc0
import { useEffect, useState } from "react";
import { fromEvent } from "rxjs";
import { map, throttleTime } from "rxjs/operators";

function useMousePosition(throttleTimer = 100) {
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);

  useEffect(() => {
    const sub = fromEvent(document, "mousemove")
      .pipe(
        throttleTime(throttleTimer),
        map((event: any) => [event.clientX, event.clientY])
      )
      .subscribe(([newX, newY]) => {
        setX(newX);
        setY(newY);
      });

    return () => {
      sub.unsubscribe();
    };
  }, [throttleTimer]);

  return {
    mouseX: x,
    mouseY: y,
  };
}
export default useMousePosition;
