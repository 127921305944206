import amazonwebservices from "../assets/skills-icons/amazonwebservices/icon.svg";
import android from "../assets/skills-icons/android/icon.svg";
import angular from "../assets/skills-icons/angularjs/icon.svg";
import apple from "../assets/skills-icons/apple/icon.svg";
import babel from "../assets/skills-icons/babel/icon.svg";
import bash from "../assets/skills-icons/bash/icon.svg";
import bootstrap from "../assets/skills-icons/bootstrap/icon.svg";
import bulma from "../assets/skills-icons/bulma/icon.svg";
import c from "../assets/skills-icons/c/icon.svg";
import css3 from "../assets/skills-icons/css3/icon.svg";
import dart from "../assets/skills-icons/dart/icon.svg";
import django from "../assets/skills-icons/django/icon.svg";
import docker from "../assets/skills-icons/docker/icon.svg";
import dotnet from "../assets/skills-icons/dot-net/icon.svg";
import electron from "../assets/skills-icons/electron/icon.svg";
import express from "../assets/skills-icons/express/icon.svg";
import figma from "../assets/skills-icons/figma/icon.svg";
import firebase from "../assets/skills-icons/firebase/icon.svg";
import flask from "../assets/skills-icons/flask/icon.svg";
import flutter from "../assets/skills-icons/flutter/icon.svg";
import gatsby from "../assets/skills-icons/gatsby/icon.svg";
import git from "../assets/skills-icons/git/icon.svg";
import github from "../assets/skills-icons/github/icon.svg";
import googlecloud from "../assets/skills-icons/googlecloud/icon.svg";
import graphql from "../assets/skills-icons/graphql/icon.svg";
import html5 from "../assets/skills-icons/html5/icon.svg";
import ionic from "../assets/skills-icons/ionic/icon.svg";
import javascript from "../assets/skills-icons/javascript/icon.svg";
import jest from "../assets/skills-icons/jest/icon.svg";
import kotlin from "../assets/skills-icons/kotlin/icon.svg";
import laravel from "../assets/skills-icons/laravel/icon.svg";
import mongodb from "../assets/skills-icons/mongodb/icon.svg";
import mysql from "../assets/skills-icons/mysql/icon.svg";
import nextjs from "../assets/skills-icons/nextjs/icon.svg";
import nodejs from "../assets/skills-icons/nodejs/icon.svg";
import nuxtjs from "../assets/skills-icons/nuxtjs/icon.svg";
import php from "../assets/skills-icons/php/icon.svg";
import postgresql from "../assets/skills-icons/postgresql/icon.svg";
import python from "../assets/skills-icons/python/icon.svg";
import rails from "../assets/skills-icons/rails/icon.svg";
import react from "../assets/skills-icons/react/icon.svg";
import redux from "../assets/skills-icons/redux/icon.svg";
import ruby from "../assets/skills-icons/ruby/icon.svg";
import rust from "../assets/skills-icons/rust/icon.svg";
import sass from "../assets/skills-icons/sass/icon.svg";
import sketch from "../assets/skills-icons/sketch/icon.svg";
import svelte from "../assets/skills-icons/svelte/icon.svg";
import swift from "../assets/skills-icons/swift/icon.svg";
import symfony from "../assets/skills-icons/symfony/icon.svg";
import tailwindcss from "../assets/skills-icons/tailwindcss/icon.svg";
import typescript from "../assets/skills-icons/typescript/icon.svg";
import vscode from "../assets/skills-icons/vscode/icon.svg";
import vuejs from "../assets/skills-icons/vuejs/icon.svg";
import wordpress from "../assets/skills-icons/wordpress/icon.svg";
import xamarin from "../assets/skills-icons/xamarin/icon.svg";
import xd from "../assets/skills-icons/xd/icon.svg";

// V.0.3
import blender from "../assets/skills-icons/blender/icon.svg";
import cplusplus from "../assets/skills-icons/cplusplus/icon.svg";
import csharp from "../assets/skills-icons/csharp/icon.svg";
import go from "../assets/skills-icons/go/icon.svg";
import illustrator from "../assets/skills-icons/illustrator/icon.svg";
import java from "../assets/skills-icons/java/icon.svg";
import kubernetes from "../assets/skills-icons/kubernetes/icon.svg";
import linux from "../assets/skills-icons/linux/icon.svg";
import photoshop from "../assets/skills-icons/photoshop/icon.svg";
import r from "../assets/skills-icons/r/icon.svg";
import spring from "../assets/skills-icons/spring/icon.svg";
import terminal from "../assets/skills-icons/terminal/icon.svg";
import threejs from "../assets/skills-icons/threejs/icon.svg";

const skills = {
  aws: {
    id: "aws",
    name: "AWS",
    icon: amazonwebservices,
    type: "BACKEND",
  },
  android: {
    id: "android",
    name: "Android",
    icon: android,
    type: "FRONTEND",
  },
  angular: {
    id: "angular",
    name: "Angular",
    icon: angular,
    type: "FRONTEND",
  },
  apple: {
    id: "apple",
    name: "Apple (iOS)",
    icon: apple,
    type: "FRONTEND",
  },
  babel: {
    id: "babel",
    name: "Babel",
    icon: babel,
    type: "FRONTEND",
  },
  bash: {
    id: "bash",
    name: "Bash",
    icon: bash,
    type: "LANGUAGE",
  },
  bootstrap: {
    id: "bootstrap",
    name: "Bootstrap",
    icon: bootstrap,
    type: "FRONTEND",
  },
  bulma: {
    id: "bulma",
    name: "Bulma",
    icon: bulma,
    type: "FRONTEND",
  },
  c: {
    id: "c",
    name: "C",
    icon: c,
    type: "LANGUAGE",
  },
  css3: {
    id: "css3",
    name: "CSS3",
    icon: css3,
    type: "FRONTEND",
  },
  dart: {
    id: "dart",
    name: "Dart",
    icon: dart,
    type: "LANGUAGE",
  },
  django: {
    id: "django",
    name: "Django",
    icon: django,
    type: "BACKEND",
  },
  docker: {
    id: "docker",
    name: "Docker",
    icon: docker,
    type: "BACKEND",
  },
  dotnet: {
    id: "dotnet",
    name: ".NET",
    icon: dotnet,
    type: "LANGUAGE",
  },
  electron: {
    id: "electron",
    name: "Electron",
    icon: electron,
    type: "FRONTEND",
  },
  express: {
    id: "express",
    name: "Express",
    icon: express,
    type: "BACKEND",
  },
  figma: {
    id: "figma",
    name: "Figma",
    icon: figma,
    type: "TOOLS",
  },
  firebase: {
    id: "firebase",
    name: "Firebase",
    icon: firebase,
    type: "BACKEND",
  },
  flask: {
    id: "flask",
    name: "Flask",
    icon: flask,
    type: "BACKEND",
  },
  flutter: {
    id: "flutter",
    name: "Flutter",
    icon: flutter,
    type: "FRONTEND",
  },
  gatsby: {
    id: "gatsby",
    name: "Gatsby",
    icon: gatsby,
    type: "FRONTEND",
  },
  git: {
    id: "git",
    name: "Git",
    icon: git,
    type: "TOOLS",
  },
  github: {
    id: "github",
    name: "Github",
    icon: github,
    type: "TOOLS",
  },
  googlecloud: {
    id: "googlecloud",
    name: "GCS",
    icon: googlecloud,
    type: "BACKEND",
  },
  graphql: {
    id: "graphql",
    name: "GraphQL",
    icon: graphql,
    type: "BACKEND",
  },
  html5: {
    id: "html5",
    name: "HTML5",
    icon: html5,
    type: "FRONTEND",
  },
  ionic: {
    id: "ionic",
    name: "Ionic",
    icon: ionic,
    type: "FRONTEND",
  },
  javascript: {
    id: "javascript",
    name: "Javascript",
    icon: javascript,
    type: "LANGUAGE",
  },
  jest: {
    id: "jest",
    name: "Jest",
    icon: jest,
    type: "TOOLS",
  },
  kotlin: {
    id: "kotlin",
    name: "Kotlin",
    icon: kotlin,
    type: "LANGUAGE",
  },
  laravel: {
    id: "laravel",
    name: "Laravel",
    icon: laravel,
    type: "BACKEND",
  },
  mongodb: {
    id: "mongodb",
    name: "MongoDB",
    icon: mongodb,
    type: "BACKEND",
  },
  mysql: {
    id: "mysql",
    name: "MySQL",
    icon: mysql,
    type: "BACKEND",
  },
  nextjs: {
    id: "nextjs",
    name: "NextJS",
    icon: nextjs,
    type: "FRONTEND",
  },
  nodejs: {
    id: "nodejs",
    name: "NodeJS",
    icon: nodejs,
    type: "BACKEND",
  },
  nuxtjs: {
    id: "nuxtjs",
    name: "NuxtJS",
    icon: nuxtjs,
    type: "FRONTEND",
  },
  php: {
    id: "php",
    name: "PHP",
    icon: php,
    type: "LANGUAGE",
  },
  postgresql: {
    id: "postgresql",
    name: "PostgreSQL",
    icon: postgresql,
    type: "BACKEND",
  },
  python: {
    id: "python",
    name: "Python",
    icon: python,
    type: "LANGUAGE",
  },
  rails: {
    id: "rails",
    name: "Rails",
    icon: rails,
    type: "BACKEND",
  },
  react: {
    id: "react",
    name: "React",
    icon: react,
    type: "FRONTEND",
  },
  redux: {
    id: "redux",
    name: "Redux",
    icon: redux,
    type: "FRONTEND",
  },
  ruby: {
    id: "ruby",
    name: "Ruby",
    icon: ruby,
    type: "LANGUAGE",
  },
  rust: {
    id: "rust",
    name: "Rust",
    icon: rust,
    type: "LANGUAGE",
  },
  sass: {
    id: "sass",
    name: "SASS",
    icon: sass,
    type: "FRONTEND",
  },
  sketch: {
    id: "sketch",
    name: "Sketch",
    icon: sketch,
    type: "TOOLS",
  },
  svelte: {
    id: "svelte",
    name: "Svelte",
    icon: svelte,
    type: "FRONTEND",
  },
  swift: {
    id: "swift",
    name: "Swift",
    icon: swift,
    type: "LANGUAGE",
  },
  symfony: {
    id: "symfony",
    name: "Symfony",
    icon: symfony,
    type: "BACKEND",
  },
  tailwindcss: {
    id: "tailwindcss",
    name: "Tailwind",
    icon: tailwindcss,
    type: "FRONTEND",
  },
  typescript: {
    id: "typescript",
    name: "Typescript",
    icon: typescript,
    type: "LANGUAGE",
  },
  vscode: {
    id: "vscode",
    name: "VSCode",
    icon: vscode,
    type: "TOOLS",
  },
  vuejs: {
    id: "vuejs",
    name: "VueJS",
    icon: vuejs,
    type: "FRONTEND",
  },
  wordpress: {
    id: "wordpress",
    name: "Wordpress",
    icon: wordpress,
    type: "TOOLS",
  },
  xamarin: {
    id: "xamarin",
    name: "Xamarin",
    icon: xamarin,
    type: "FRONTEND",
  },
  xd: {
    id: "xd",
    name: "Adobe XD",
    icon: xd,
    type: "TOOLS",
  },
  // Add on v.0.3
  blender: {
    id: "blender",
    name: "Blender",
    icon: blender,
    type: "TOOLS",
  },
  cplusplus: {
    id: "cplusplus",
    name: "C++",
    icon: cplusplus,
    type: "LANGUAGE",
  },
  csharp: {
    id: "csharp",
    name: "C#",
    icon: csharp,
    type: "LANGUAGE",
  },
  go: {
    id: "go",
    name: "Go",
    icon: go,
    type: "LANGUAGE",
  },
  illustrator: {
    id: "illustrator",
    name: "Illustrator",
    icon: illustrator,
    type: "TOOLS",
  },
  java: {
    id: "java",
    name: "Java",
    icon: java,
    type: "LANGUAGE",
  },
  kubernetes: {
    id: "kubernetes",
    name: "Kubernetes",
    icon: kubernetes,
    type: "BACKEND",
  },
  linux: {
    id: "linux",
    name: "Linux",
    icon: linux,
    type: "TOOLS",
  },
  photoshop: {
    id: "photoshop",
    name: "Photoshop",
    icon: photoshop,
    type: "TOOLS",
  },
  r: {
    id: "r",
    name: "R",
    icon: r,
    type: "LANGUAGE",
  },
  spring: {
    id: "spring",
    name: "Spring",
    icon: spring,
    type: "BACKEND",
  },
  terminal: {
    id: "terminal",
    name: "Terminal",
    icon: terminal,
    type: "TOOLS",
  },
  threejs: {
    id: "threejs",
    name: "Threejs",
    icon: threejs,
    type: "FRONTEND",
  },
};
export default skills as Record<string, any>;
